import React from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { AllApi } from '../../Api/AllApi'
import DoneIcon from '@mui/icons-material/Done';
// import One from './../../Public/Image/Game/topjoiner/Group 26086570.svg'
import BronzeImg from './../../Public/Image/Game/bronze.svg'
import SilverImg from './../../Public/Image/Game/Silver.svg'
import GoldImg from './../../Public/Image/Game/Gold.svg'
import PlatinumImg from './../../Public/Image/Game/Platinum.svg'
import DimondImg from './../../Public/Image/Game/Dimond.svg'
import EpicImg from './../../Public/Image/Game/Epic.svg'
import LegendryImg from './../../Public/Image/Game/Legendry.svg'
import MasterImg from './../../Public/Image/Game/Master.svg'
import GrandMasterImg from './../../Public/Image/Game/GrandMaster.svg'
import LordImg from './../../Public/Image/Game/Lord.svg'
import CreatorImg from './../../Public/Image/Game/Creator.svg'

import Lord from './../../Public/Image/Game/Claim/Lord.svg'
import { SetPromotionBottomSheet } from './../../app/Game'
import { SetClaimRewardCall } from './../../app/Game'

import './Promotion.css'
import Coin from './../../Public/Image/Game/Coin.svg'
import Star from './../../Public/Image/Game/Star.svg'
import { useSelector, useDispatch } from 'react-redux'
import Skeleton from '@mui/material/Skeleton';

const Promotion = () => {
    const dispatch = useDispatch()
    const PromotionBottomSheet = useSelector((state) => state.Game.PromotionBottomSheet)
    const UserReward = useSelector((state) => state.Game.UserReward)
    const DataClaimReward = useSelector((state) => state.Game.DataClaimReward)

    // const [ClaimRewardUpdated, setClaimRewardUpdated] = useState(false)

    const [League, setLeague] = useState('')

    const [ForStyle, setForStyle] = useState('')
    const [Rank, setRank] = useState('')
    const [IsMultiClaim, setIsMultiClaim] = useState(false)
    const [MultiRewardClaim, setMultiRewardClaim] = useState([])
    const [SingleRewardClaim, setSingleRewardClaim] = useState([])
    const [LoadingState, setLoadingState] = useState(true)

    const Style = {
        Bronze: {
            img: BronzeImg,
            Rank: '#682920',
            Progress: '#BF8970',
            ListBorder: '#BF8970',
            ListTitleBg: '#BF8970',
            ListTitleText: '#321203',
            ListTitleYou: '#4B2714',
            ListTitleYouBg: '#FFEDE6',
            ListBodyBg: '#FFEDE6',
            ListBodyText: '#321203'
        },
        Silver: {
            img: SilverImg,
            Rank: '#5E7081',
            Progress: '#8E9FAF',
            ListBorder: '#8E9FAF',
            ListTitleBg: '#8E9FAF',
            ListTitleText: '#22323F',
            ListTitleYou: '#22323F',
            ListTitleYouBg: '#F3F3F3',
            ListBodyBg: '#F3F3F3',
            ListBodyText: '#384956'
        },
        Gold: {
            img: GoldImg,
            Rank: '#8F4F2C',
            Progress: '#FFD100',
            ListBorder: '#FFD100',
            ListTitleBg: '#FFD100',
            ListTitleText: '#3C2F00',
            ListTitleYou: '#3C2F00',
            ListTitleYouBg: '#FFF0C9',
            ListBodyBg: '#FFF0C9',
            ListBodyText: '#564500'
        },
        Platinum: {
            img: PlatinumImg,
            Rank: '#373D37',
            Progress: '#ACB2AC',
            ListBorder: '#ACB2AC',
            ListTitleBg: '#ACB2AC',
            ListTitleText: '#2C322E',
            ListTitleYou: '#2C322E',
            ListTitleYouBg: '#DEE4DD',
            ListBodyBg: '#DEE4DD',
            ListBodyText: '#424844'
        },
        Dimond: {
            img: DimondImg,
            Rank: '#24B6CA',
            Progress: '#B9F2FF',
            ListBorder: '#B9F2FF',
            ListTitleBg: '#B9F2FF',
            ListTitleText: '#00363E',
            ListTitleYou: '#2C322E',
            ListTitleYouBg: '#EDFCFF',
            ListBodyBg: '#EDFCFF',
            ListBodyText: '#0B4E59'
        },
        Epic: {
            img: EpicImg,
            Rank: '#42017E',
            Progress: '#8C49CA',
            ListBorder: '#8C49CA',
            ListTitleBg: '#8C49CA',
            ListTitleText: '#FAECFF',
            ListTitleYou: '#4A007F',
            ListTitleYouBg: '#FAECFF',
            ListBodyBg: '#FAECFF',
            ListBodyText: '#4A007F'
        },
        Legendary: {
            img: LegendryImg,
            Rank: '#851212',
            Progress: '#C00021',
            ListBorder: '#C00021',
            ListTitleBg: '#C00021',
            ListTitleText: '#FFEDEB',
            ListTitleYou: '#68000D',
            ListTitleYouBg: '#FFEDEB',
            ListBodyBg: '#FFEDEB',
            ListBodyText: '#68000D'
        },
        Master: {
            img: MasterImg,
            Rank: '#2424CD',
            Progress: '#5656FF',
            ListBorder: '#5656FF',
            ListTitleBg: '#5656FF',
            ListTitleText: '#FFFBFF',
            ListTitleYou: '#1300A9',
            ListTitleYouBg: '#F2EFFF',
            ListBodyBg: '#F2EFFF',
            ListBodyText: '#00006E'
        },
        Grandmaster: {
            img: GrandMasterImg,
            Rank: '#1C1C86',
            Progress: '#0202D3',
            ListBorder: '#0202D3',
            ListTitleBg: '#0202D3',
            ListTitleText: '#E0E0FF',
            ListTitleYou: '#0202A9',
            ListTitleYouBg: '#E0E0FF',
            ListBodyBg: '#E0E0FF',
            ListBodyText: '#0202A9'
        },
        Lord: {
            img: Lord,
            Rank: '#112E1F',
            Progress: '#006C44',
            ListBorder: '#006C44',
            ListTitleBg: '#006C44',
            ListTitleText: '#C0FFD7',
            ListTitleYou: '#005232',
            ListTitleYouBg: '#C0FFD7',
            ListBodyBg: '#C0FFD7',
            ListBodyText: '#005232'
        },
        Creator: {
            img: CreatorImg,
            Rank: '#4E4E4E',
            Progress: '#212121',
            ListBorder: '#212121',
            ListTitleBg: '#212121',
            ListTitleText: '#FFFFFF',
            ListTitleYou: '#212121',
            ListTitleYouBg: '#FFFFFF',
            ListBodyBg: '#E0E0E0',
            ListBodyText: '#212121'
        },
    }
    function onDismiss() {
        ClaimReward()
        setTimeout(() => {
            dispatch(SetPromotionBottomSheet(false))
        }, 300);

    }
    const [Reward, setReward] = useState(false)
    useEffect(() => {

        CheckRewardUser()
        // console.log(UserReward)
    }, [UserReward])
    const CheckRewardUser = () => {
        setLoadingState(true)
        // const timer = setInterval(async () => {
            setMultiRewardClaim([])
            // console.log(Reward)

            if (UserReward) {
                // clearInterval(timer);
                setReward(UserReward)
                // console.log(UserReward)
                // console.log(DataClaimReward)
                // console.log(PromotionBottomSheet)
          
            
                    if (DataClaimReward) {
                        let AppSettingL = DataClaimReward.LeaguesAppSetting

                        let targetL = DataClaimReward.TargetLeague - 1
                        let PrevL = DataClaimReward.prevLeague - 1
                        // console.log(targetL)
                        // console.log(PrevL)
                        // console.log(targetL - PrevL)
                        AppSettingL.map((item, i) => {
                            if (targetL - PrevL > 1) {
                                setIsMultiClaim(true)
                                if (PrevL <= i && i < targetL) {
                                    // console.log(i)
                                    // console.log(item)
                                    setMultiRewardClaim((prevItems) => [...prevItems, item])
                                }
                            } else if (targetL - PrevL == 1) {
                                // console.log(PrevL)
                                if (PrevL == i) {
                                    setSingleRewardClaim(item)
                                }
                                setIsMultiClaim(false)

                            }
                            else if (true) {

                            }
                        })
                    }

                    // console.log(LeaguesAppSetting)
                    if (UserReward) {
                        if (UserReward.leagueName.indexOf('Bronze') >= 0) {
                            setLeague('Bronze')
                            setForStyle(Style.Bronze)
                            var rank = UserReward.leagueName.replace('Bronze', '');
                            setRank(rank)
                        } else if (UserReward.leagueName.indexOf('Silver') >= 0) {
                            setLeague('Silver')
                            setForStyle(Style.Silver)
                            var rank = UserReward.leagueName.replace('Silver', '');
                            setRank(rank)
                        }
                        else if (UserReward.leagueName.indexOf('Gold') >= 0) {
                            setLeague('Gold')
                            setForStyle(Style.Gold)
                            var rank = UserReward.leagueName.replace('Gold', '');
                            setRank(rank)
                        }
                        else if (UserReward.leagueName.indexOf('Platinum') >= 0) {
                            setLeague('Platinum')
                            setForStyle(Style.Platinum)
                            var rank = UserReward.leagueName.replace('Platinum', '');
                            setRank(rank)
                        }
                        else if (UserReward.leagueName.indexOf('Diamond') >= 0) {
                            setLeague('Dimond')
                            setForStyle(Style.Dimond)
                            var rank = UserReward.leagueName.replace('Diamond', '');
                            setRank(rank)
                        }
                        else if (UserReward.leagueName.indexOf('Epic') >= 0) {
                            setLeague('Epic')
                            setForStyle(Style.Epic)
                            var rank = UserReward.leagueName.replace('Epic', '');
                            setRank(rank)
                        }
                        else if (UserReward.leagueName.indexOf('Legendary') >= 0) {
                            setLeague('Legendary')
                            setForStyle(Style.Legendary)
                            var rank = UserReward.leagueName.replace('Legendary', '');
                            setRank(rank)
                        }
                        else if (UserReward.leagueName.indexOf('Master') >= 0) {
                            setLeague('Master')
                            setForStyle(Style.Master)
                            var rank = UserReward.leagueName.replace('Master', '');
                            setRank(rank)

                        }
                        else if (UserReward.leagueName.indexOf('Grandmaster') >= 0) {
                            setLeague('Grandmaster')
                            setForStyle(Style.Grandmaster)
                            var rank = UserReward.leagueName.replace('Grandmaster', '');
                            setRank(rank)
                        }
                        else if (UserReward.leagueName.indexOf('Lord') >= 0) {
                            setLeague('Lord')
                            setForStyle(Style.Lord)
                            var rank = UserReward.leagueName.replace('Lord', '');
                            setRank(rank)

                        }
                        else if (UserReward.leagueName.indexOf('Creator') >= 0) {
                            setLeague('Creator')
                            setForStyle(Style.Creator)
                            var rank = UserReward.leagueName.replace('Creator', '');
                            setRank(rank)
                        }
                    }
                
            }else{
                // console.log(Reward)
            }


        // }, 1000);
        setTimeout(() => {
            setLoadingState(false)
        }, 1500);


    }

    const ClaimReward = async () => {
        let Data;
        const MakeApiClass = new AllApi();
        let Result = await MakeApiClass.ClaimReward()
        // console.log(Result)
        if (Result.code == 200) {
            Data = Result
            dispatch(SetClaimRewardCall(true))
        } else if (Result.code == 999) {
            Data = 'Network'
        } else if (Result.code == 301) {
            console.log('error claim reward')
        } else if (Result.code == 302) {
            console.log('error tekrari')
        }
        return Data
    }
    let Sum = 0;
    const getSumCoin = (MultiRewardClaim) => {
        // console.log(MultiRewardClaim)

        for (let i = 0; i < MultiRewardClaim.length; i++) {
            Sum += MultiRewardClaim[i].coin;
        }
        return abbreviate_number(Sum)
    }
    let Sum1 = 0;
    const getSumXp = (MultiRewardClaim) => {
        // console.log(MultiRewardClaim)

        for (let i = 0; i < MultiRewardClaim.length; i++) {
            Sum1 += MultiRewardClaim[i].xp;
        }
        return abbreviate_number(Sum1)
    }

    function abbreviate_number(num, fixed) {

        if (num === null || num == undefined || num == false) { return null; } // terminate early
        if (num === 0) { return '0'; } // terminate early
        fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
        var b = (num).toPrecision(2).split("e"), // get power
            k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
            c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
            d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
            e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power
        return e;
    }
    // console.log(MultiRewardClaim)
    return (
        <BottomSheet onDismiss={onDismiss}
            open={PromotionBottomSheet}

        >
            <div className='CongratulationsBottomSheet'>
                <div className='CongratulationsBottomSheetHeader'>
                    <div className='CongratulationsBottomSheetHeaderText'>Promoted to the <b>{Reward.leagueName}</b></div>
                </div>
                <div className='CongratulationsBottomSheetBody'>
                    {/* {LoadingState ?
                        <Skeleton variant="circular" width={128} height={128} />
                        : */}
                        <img src={ForStyle.img} height={128} width={128} />
                    {/* // } */}



                    <div className='GameLeagueNamePromotion' style={{ backgroundColor: ForStyle.Rank }}>
                        {/* {MyLeagueRank} */}
                        {Rank}
                    </div>
                    <div className='CongratulationsBottomSheetBodyReward'>
                        You Rewards:
                    </div>
                    {
                        IsMultiClaim ?
                            <>
                                <div className='PromotionRewardMulti'>
                                    <div className='PromotionRewardMultiProgress'>
                                        <div className='VerticalLineItem' style={{ backgroundColor: '#E0E0E0' }}>

                                            <DoneIcon className='VerticalLineItemIcon' style={{ color: '#212121' }} />
                                        </div>
                                        <div className='PromotionRewardMultiBordering'></div>
                                        {MultiRewardClaim.length > 2 ? <div className='PromotionRewardMultiNumber'> +{MultiRewardClaim.length - 2}</div> : <div className='PromotionRewardMultiBordering'></div>}
                                        <div className='PromotionRewardMultiBordering'></div>
                                        <div className='VerticalLineItem' style={{ backgroundColor: '#E0E0E0' }}>

                                            <DoneIcon className='VerticalLineItemIcon' style={{ color: '#212121' }} />
                                        </div>

                                    </div>
                                    <div className='PromotionRewardMultiNames'>
                                        <div className='PromotionRewardMultiPrev'>
                                            <div className='PromotionRewardMultiPrevName'>{MultiRewardClaim[0].leagueName}</div>
                                            <div className='PromotionRewardMultiPrevReward'>
                                                {MultiRewardClaim[0].coin ? abbreviate_number(MultiRewardClaim[0].coin) : 0} <img src={Coin} className='CongratulationsBottomSheetBodyRewardCoin' />
                                                +
                                                {MultiRewardClaim[0].xp ? abbreviate_number(MultiRewardClaim[0].xp) : 0} <img src={Star} className='CongratulationsBottomSheetBodyRewardStar' />
                                            </div>
                                        </div>
                                        <div className='PromotionRewardMultiTarget'>

                                            <div className='PromotionRewardMultiPrevName'> {MultiRewardClaim[MultiRewardClaim.length - 1].leagueName}</div>
                                            <div className='PromotionRewardMultiPrevReward'>
                                                {MultiRewardClaim[MultiRewardClaim.length - 1].coin ? abbreviate_number(MultiRewardClaim[MultiRewardClaim.length - 1].coin) : 0} <img src={Coin} className='CongratulationsBottomSheetBodyRewardCoin' />
                                                +
                                                {MultiRewardClaim[MultiRewardClaim.length - 1].xp ? abbreviate_number(MultiRewardClaim[MultiRewardClaim.length - 1].xp) : 0} <img src={Star} className='CongratulationsBottomSheetBodyRewardStar' />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className='PromotionRewardMultiSum'>
                                    <div className='PromotionRewardMultiSumTitle'>
                                        Total rewards :
                                    </div>
                                    <div className='PromotionRewardMultiSumDes'>
                                        {getSumCoin(MultiRewardClaim)} <img src={Coin} className='CongratulationsBottomSheetBodyRewardCoin' />
                                        +  {getSumXp(MultiRewardClaim)}  <img src={Star} className='CongratulationsBottomSheetBodyRewardStar' />
                                    </div>
                                </div>
                            </>

                            :
                            <div className='CongratulationsBottomSheetBodyRewardNumber'>

                                {SingleRewardClaim.coin ? abbreviate_number(SingleRewardClaim.coin) : 0} <img src={Coin} className='CongratulationsBottomSheetBodyRewardCoin' />
                                + {SingleRewardClaim.xp ? abbreviate_number(SingleRewardClaim.xp) : 0} <img src={Star} className='CongratulationsBottomSheetBodyRewardStar' />



                            </div>
                    }


                </div>
                <div className='CongratulationsBottomSheetFooter'>

                    <button className='CongratulationsBottomSheetButton'
                        onClick={() => { dispatch(SetPromotionBottomSheet(false)); ClaimReward() }} >
                        {IsMultiClaim ? 'Confirm' : 'Claim Reward'}
                    </button>
                </div>
            </div>
        </BottomSheet>
    )
}

export default Promotion 