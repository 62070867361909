import React from 'react'
import { useEffect } from 'react';
import { GameDB } from './../DataBase/GameDB'
import { AllApi } from './../Api/AllApi'
import { CalcTimeStartGame } from './CalcTimeStartGame'
import { OnlineRequestAppSettingGame } from './OnlineRequestAppSettingGame'
import { OnlineRequestProfileGame } from './OnlineRequestProfileGame'
import { OnlineRequestLeagueListGame } from './OnlineRequestLeagueListGame'
import { OnlineRequestTopJoinerGame } from './OnlineRequestTopJoinerGame'
import {
    SetAppSettingData,
    SetUserLeagueData,
    SetMyLeagueRank,
    SetLeagueLimitJoinProgress,
    SetLeagueMinimumJoinProgress,
    SetRewardLeague,
    SetMyLeagueRankNumber,
    SetGiftTopjoin,
    SetNameAccount,
    SetJoinCountRedux,
    SetPromotionBottomSheet,
    SetUserReward,
    SetClaimRewardCall,
    SetDataClaimReward,
    SetXpLimit,
    SetXpCount,
    SetLevelUserHeaderProgress,
    SetSnackBarEndLeagueState, SetTopJoinerTime, SetIsBetweenLeaguge
} from './../app/Game'
import { SetCongratulationsBottomSheet } from './../app/Game'
import { SetCongratulationsBottomSheet2 } from './../app/Game'
import { SetCongratulationsBottomSheet3 } from './../app/Game'
// export async function GameStart() {
export class CheckDataBaseGame extends React.Component {
    constructor() {
        super();
        // this.Token = GetCookie('Token')


    }
    async CheckDataBase(dispatch) {

        try {
            GameDB.AppSetting.toArray(async function (AppSettingItem) {
                const CalcTimeStartGameClass = new CalcTimeStartGame();
                const OnlineRequestAppSettingGameGameClass = new OnlineRequestAppSettingGame();

                if (AppSettingItem.length > 0) {

                    if (AppSettingItem[0].LastTime != false) {



                        let ResCalc = await CalcTimeStartGameClass.CalcTimeStart(AppSettingItem[0].LastTime)


                        if (ResCalc.minutes < 0 && ResCalc.seconds < 0) {


                            GameDB.AppSetting.toCollection().modify(Time => {
                                Time.LastTime = false;
                            });


                            let ResCalcOnlineRequestAppSetting = await OnlineRequestAppSettingGameGameClass.OnlineRequestAppSetting()
                            // console.log('OnlineRequestAppSetting')


                        } else {
                            // OfflineRequestAppSetting()
                            // console.log('OfflineRequestAppSetting')


                        }

                    } else {


                        await OnlineRequestAppSettingGameGameClass.OnlineRequestAppSetting()
                        // console.log('OnlineRequestAppSetting')

                    }
                } else {
                    // console.log('OnlineRequestAppSetting')


                    await OnlineRequestAppSettingGameGameClass.OnlineRequestAppSetting()
                    // return 'online'
                }
            });
            GameDB.Profile.toArray(async function (ProfileItem) {
                const CalcTimeStartGameClass = new CalcTimeStartGame();
                const OnlineRequestProfileGameClass = new OnlineRequestProfileGame();
                if (ProfileItem.length > 0) {
                    if (ProfileItem[0].LastTime != false) {
                        let ResCalc = await CalcTimeStartGameClass.CalcTimeStart(ProfileItem[0].LastTime)
                        // console.log(ResCalc)
                        // let ResCalc = CalcTimeStart(ProfileItem[0].LastTime)
                        if (ResCalc.minutes < 0 && ResCalc.seconds < 0) {
                            GameDB.Profile.toCollection().modify(Time => {
                                Time.LastTime = false;
                            });
                            // console.log('OnlineRequestProfile')

                            await OnlineRequestProfileGameClass.OnlineRequestProfile()
                        } else {
                            // OfflineRequestProfile()
                            // console.log('OfflineRequestProfile')

                        }
                    } else {
                        // console.log('OnlineRequestProfile')

                        await OnlineRequestProfileGameClass.OnlineRequestProfile()
                    }
                } else {
                    // console.log('OnlineRequestProfile')

                    await OnlineRequestProfileGameClass.OnlineRequestProfile()
                }
            });

            GameDB.LeagueList.toArray(async function (LeagueListItem) {

                const CalcTimeStartGameClass = new CalcTimeStartGame();
                const OnlineRequestLeagueListGameClass = new OnlineRequestLeagueListGame();
                // console.log(LeagueListItem)
                if (LeagueListItem.length > 0) {
                    if (LeagueListItem[0].LastTime != false) {
                        let ResCalc = await CalcTimeStartGameClass.CalcTimeStart(LeagueListItem[0].LastTime)


                        // let ResCalc = CalcTimeStart(LeagueListItem[0].LastTime)
                        if (ResCalc.minutes < 0 && ResCalc.seconds < 0) {

                            GameDB.LeagueList.toCollection().modify(Time => {
                                Time.LastTime = false;
                            });


                            let res = await OnlineRequestLeagueListGameClass.OnlineRequestLeagueList(dispatch)
                            // console.log(res)
                            // console.log('OnlineRequestLeagueList')
                            dispatch(SetJoinCountRedux(res.data.user.joinCount))
                        } else {
                            // console.log('OfflineRequestLeagueList')

                            let LeagueList = await GameDB.LeagueList.toArray()
                            if (LeagueList.length > 0) {
                                // console.log(LeagueList[0])
                                dispatch(SetUserLeagueData(LeagueList[0].LeagueList.data))
                                dispatch(SetLevelUserHeaderProgress(LeagueList[0].LeagueList))
                                dispatch(SetJoinCountRedux(LeagueList[0].LeagueList.user.joinCount))
                                let LevelUser = false
                                LevelUser = LeagueList[0].LeagueList.data.userInformation.leagueLevel

                                // let ResOfCalc = await GetUserDataFromAppSetting(LevelUser)


                                // let cashTime = LeagueList[0].LeagueList.data.cashTime
                                // let LastTime = LeagueList[0].LastTime
                                // clearInterval(intervalRef.current);
                                // localStorage.setItem('LastTimeLeague', LastTime)
                                // setLastTime(LastTime)
                                // setCashTime(cashTime)
                                // setRunInterval(true)
                            } else {
                                // console.log('error')
                                // setNoInternet(true)
                                // const timer = setInterval(async () => {
                                //     LeagueList = await GameDB.LeagueList.toArray()
                                //     // console.log(LeagueList)
                                //     if (LeagueList.length == 1) {
                                //         clearInterval(timer);
                                //         OfflineRequestLeagueList()
                                //         // setNoInternet(false)
                                //     } else {
                                //         // console.log('error again !!!')
                                //     }

                                // }, 1000);

                            }
                            // this.OfflineRequestLeagueList()
                        }
                    } else {
                        // console.log('OnlineRequestLeagueList')

                        let res = await OnlineRequestLeagueListGameClass.OnlineRequestLeagueList(dispatch)
                        console.log(res)
                        dispatch(SetJoinCountRedux(res.data.user.joinCount))

                    }
                } else {
                    // console.log('OnlineRequestLeagueList')

                    let res = await OnlineRequestLeagueListGameClass.OnlineRequestLeagueList(dispatch)
                    // console.log(res)

                    dispatch(SetJoinCountRedux(res.data.user.joinCount))
                    // OnlineRequestLeagueList()
                }
            });
            GameDB.TopJoiner.toArray(async function (TopJoinerItem) {
                const CalcTimeStartGameClass = new CalcTimeStartGame();
                const OnlineRequestTopJoinerGameClass = new OnlineRequestTopJoinerGame();
                if (TopJoinerItem.length > 0) {
                    if (TopJoinerItem[0].LastTime != false) {
                        // let ResCalc = CalcTimeStart(TopJoinerItem[0].LastTime)
                        let ResCalc = await CalcTimeStartGameClass.CalcTimeStart(TopJoinerItem[0].LastTime)
                        if (ResCalc.minutes < 0 && ResCalc.seconds < 0) {

                            GameDB.TopJoiner.toCollection().modify(Time => {
                                Time.LastTime = false;
                            });
                            // OnlineRequestTopJoiner()
                            await OnlineRequestTopJoinerGameClass.OnlineRequestTopJoiner()
                        } else {
                            // console.log('OfflineRequestTopJoiner')
                            this.OfflineRequestTopJoiner()
                        }
                    } else {

                        OnlineRequestTopJoinerGameClass.OnlineRequestTopJoiner()
                        // OnlineRequestTopJoiner()
                    }
                } else {

                    OnlineRequestTopJoinerGameClass.OnlineRequestTopJoiner()
                    // OnlineRequestTopJoiner()
                }
            });
        } catch (error) {
            // console.log(error)
        }
    }
    async OfflineRequestTopJoiner(dispatch) {
        // setLoadingState(true)
        // dispatch(SetCongratulationsBottomSheet(true))
        // console.log('OfflineRequestTopJoiner')
        let TopJoiner = await GameDB.TopJoiner.toArray()
        // console.log(TopJoiner)
        // setTopjoinerState(TopJoiner[0].TopJoiner)
        // setMyUser(TopJoiner[0].userInformation)

        TopJoiner[0].TopJoiner.map((item, i) => {
            // console.log(item)
            // console.log(IsBetweenLeaguge)
            // console.log(TopJoiner[0].userInformation.email)
            // console.log(item.email)
            if (item.email == TopJoiner[0].userInformation.email) {
                // console.log(i + 1)
                // console.log(IsBetweenLeaguge)
                // setIsInTop100(i + 1)
                // console.log(0)
                let ShowPrevBottomSheet = localStorage.getItem('CloseCongratulations')
                let curTime = Date.now();
                console.log(ShowPrevBottomSheet)
                if (curTime + 3600000 < ShowPrevBottomSheet || ShowPrevBottomSheet == null) {
                    // if (IsBetweenLeaguge) {
                    // console.log(1)
                    if ((i + 1) == 1) {
                        // console.log(i + 1)/
                        setTimeout(() => {
                            dispatch(SetCongratulationsBottomSheet(true))
                        }, 1000);

                    } else if ((i + 1) == 2) {
                        setTimeout(() => {
                            dispatch(SetCongratulationsBottomSheet2(true))
                        }, 1000);


                    } else if ((i + 1) == 3) {
                        setTimeout(() => {
                            dispatch(SetCongratulationsBottomSheet3(true))
                        }, 1000);


                    }
                    // }
                }
            }
            // else{
            //   setIsInTop100(i+1)

            // }
        })
        // setLoadingState(false)

    }
    async CheckClaimReward(dispatch, JoinCountRedux) {

        // let JR = 30
        // async function* generateNumbers() {
        //     for (let i = 29; i < 85; i++) {
        //       yield i;
        //     }
        //   }

        //   async function main() {
        //     for await (const number of generateNumbers()) {
        //         console.log(number);
        //         JR=number
        // console.log(JoinCountRedux) 
        // /////////////////////////////////////////////////////////////////////////
        let JR = JoinCountRedux
        // console.log(JR);

        let AppSetting = await GameDB.AppSetting.toArray()
        let Profile = await GameDB.Profile.toArray()

        let LeagueLevelProfile
        if (Profile.length > 0) {

            LeagueLevelProfile = Profile[0].Profile.leagueLevel
        }
        if (AppSetting.length > 0) {

            let LeaguesAppSetting = AppSetting[0].AppSetting.leagues

            LeaguesAppSetting.map((item, i) => {
                let min = item.min
                let max = item.max ? item.max : false
                let PrevIndex
                if (LeagueLevelProfile == item.leagueLevel) { PrevIndex = i }

                if (min <= JR && JR < max) {

                    if (LeagueLevelProfile == item.leagueLevel) {
                        // no need to claim reward
                        // console.log(item)

                    } else {
                        // need to claim reward
                        if (item.leagueLevel - LeagueLevelProfile > 0) {
                            let Comparelevel = item.leagueLevel - LeagueLevelProfile
                            // console.log(item)
                            dispatch(SetUserReward(item))
                            let dataClaim = { prevLeague: LeagueLevelProfile, TargetLeague: item.leagueLevel, LeaguesAppSetting }

                            dispatch(SetDataClaimReward(dataClaim))
                            dispatch(SetPromotionBottomSheet(true))
                        }

                    }
                } else if (min <= JR && max == false) {
                    if (LeagueLevelProfile == item.leagueLevel) {
                        // no need to claim reward


                    } else {
                        // need to claim reward
                        if (item.leagueLevel - LeagueLevelProfile > 0) {
                            let Comparelevel = item.leagueLevel - LeagueLevelProfile


                            dispatch(SetUserReward(item))
                            let dataClaim = { prevLeague: LeagueLevelProfile, TargetLeague: item.leagueLevel, LeaguesAppSetting }
                            dispatch(SetDataClaimReward(dataClaim))
                            dispatch(SetPromotionBottomSheet(true))
                        }

                    }
                }
            })


        }
        // ///////////////////////////////////////////////////////////////////////////
        //       await new Promise(resolve => setTimeout(resolve, 1000));
        //     }
        //   }
        //   main()

    }
    async CollectDataAfterClaim() {
        const OnlineRequestLeagueListGameClass = new OnlineRequestLeagueListGame();
        const OnlineRequestProfileGameClass = new OnlineRequestProfileGame();
        const OnlineRequestTopJoinerGameClass = new OnlineRequestTopJoinerGame();

        await OnlineRequestLeagueListGameClass.OnlineRequestLeagueList()
        await OnlineRequestProfileGameClass.OnlineRequestProfile()
        await OnlineRequestTopJoinerGameClass.OnlineRequestTopJoiner()
    }
    async TimerLeague(dispatch) {
        let LeagueList = await GameDB.LeagueList.toArray()
        if (LeagueList[0] != undefined) {
            let secondsData = LeagueList[0].LeagueList.data.timeFinishLeague
            // secondsData = 10
            if (secondsData >= 0) {
                dispatch(SetIsBetweenLeaguge(false))
                setTimeout(() => {
                    dispatch(SetSnackBarEndLeagueState(false))
                }, 5000);

                GameDB.LeagueList.toArray(function (LeagueListItem) {
                    if (LeagueListItem.length > 0) {
                        GameDB.LeagueList.toCollection().modify(LeagueListItem => {
                            LeagueListItem.LeagueList.data.timeFinishLeague = secondsData - 1;
                        });
                    }
                });
                const days = Math.floor(secondsData / 86400);
                const hours = Math.floor((secondsData % 86400) / 3600);
                const minutes = Math.floor((secondsData % 3600) / 60);
                const secondsLeft = secondsData % 60;
                let m, h, s, day;
                m = String(minutes).padStart(2, "0");
                h = String(hours).padStart(2, "0");
                s = String(secondsLeft).padStart(2, "0");
                day = String(days).padStart(2, "0");
                // console.log(day, h, m, s)
                dispatch(SetTopJoinerTime({
                    days: day,
                    hours: h,
                    minutes: m,
                    seconds: s,
                }))
            } else if (secondsData <= 0) {
                // setLoadState(true)
                dispatch(SetIsBetweenLeaguge(true))
                this.OfflineRequestTopJoiner(dispatch)

                // let GetleagueListJoinsRes = await GetleagueListJoinsfunc()
                // console.log(GetleagueListJoinsRes)
                // if (GetleagueListJoinsRes != 'Network') {
                //     GameDB.LeagueList.toArray(function (LeagueListItem) {
                //         if (LeagueListItem.length > 0) {
                //             GameDB.LeagueList.toCollection().modify(LeagueListItem => {
                //                 LeagueListItem.LeagueList = GetleagueListJoinsRes.data;
                //             });
                //         }
                //     });
                // }

                let curTime = Date.now();
                let CloseSnackBarEndLeagueState = localStorage.getItem('CloseSnackBarEndLeagueState')
                if (curTime + 3600000 < CloseSnackBarEndLeagueState || CloseSnackBarEndLeagueState == null) {
                    dispatch(SetSnackBarEndLeagueState(true))
                }
                setTimeout(() => {
                    const OnlineRequestLeagueListGameClass = new OnlineRequestLeagueListGame();
                    const OnlineRequestProfileGameClass = new OnlineRequestProfileGame();
                    const OnlineRequestTopJoinerGameClass = new OnlineRequestTopJoinerGame();
                const OnlineRequestAppSettingGameGameClass = new OnlineRequestAppSettingGame();
                    
                    OnlineRequestTopJoinerGameClass.OnlineRequestTopJoiner()
                    OnlineRequestAppSettingGameGameClass.OnlineRequestAppSetting()
                    OnlineRequestLeagueListGameClass.OnlineRequestLeagueList()
                    OnlineRequestProfileGameClass.OnlineRequestProfile()
                }, 7000);

                // setLoadState(false)

            }
        }
        // console.log('first')
    }
}
