import React from 'react';
import './App.css';
import Auth from './Auth/Auth'
import Login from './Auth/Login'
import Register from './Auth/Register'
import RegisterGetPassword from './Auth/RegisterGetPassword'
import Home from './Home/Home'
import GetPhoneNumber from './Auth/GetPhoneNumber'
import CheckVerificationCode from './Auth/CheckVerificationCode'
import ForgetPassword from './Auth/ForgetPassword'
import ForgetPasswordGetCode from './Auth/ForgetPasswordGetCode'
import ChangePassword from './Auth/ChangePassword';
import Coin from './Coin/Coin'
import GetLinkUsername from './Home/GetLinkUsername'
import GetUsernameView from './Home/GetUsernameView'
import Notice from './Home/Notice'
import TransferHistory from './Coin/TransferHistory';
import GiftCode from './Coin/GiftCode'
import Profile from './Profile/Profile'
import AccountInformation from './Profile/AccountInformation'
import Payments from './Profile/Payments'
import LogOut from './Profile/LogOut'
import DeleteAccount from './Profile/DeleteAccount'
import AddNumber from './Profile/AddNumber'
import AddNumberVerify from './Profile/AddNumberVerify'
import EditNumber from './Profile/EditNumber'
import EditNumberVerify from './Profile/EditNumberVerify'
import Privacy from './Profile/Privacy'
import SetPassword from './Profile/SetPassword'
import GetCheckPassword from './Profile/GetCheckPassword'
import GetChangePassword from './Profile/GetChangePassword'
import Orders from './MyOrder/Orders'
import OrderDetailsSingle from './MyOrder/OrderDetailsSingle'
import OrderDetailsAll from './MyOrder/OrderDetailsAll'
import Game from './Game/Game'
import AllReward from './Game/AllReward/AllReward'

import SellingPhoneNumbers from './Sale/SellingPhoneNumbers'
import SaleHistory from './Sale/SaleHistory'
import VerificationCode from './Sale/VerificationCode'
import EditProfile from './Profile/EditProfile';
import SetName from './Profile/SetName/SetName';
import "./Public/fonts/font.css"
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LoginTelegram from './TelegramLogin/LoginTelegram'
import VerifyCode from './TelegramLogin/VerifyCode'
import JoinBot from './TelegramLogin/JoinBot'
import AutoManualJoin from './Coin/AutoManualJoin';
import Update from './BottomSheet/Update'
import { useState, useRef } from 'react';
import { useEffect } from 'react';

import SnackBarEndLeague from './Game/GlobalGameComponent/SnackBarEndLeague'
import { CheckDataBaseGame } from './GameAction/CheckDataBaseGame'
import { useSelector, useDispatch } from 'react-redux'
import { GetCookie } from './Action/GetCookie';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Congratulations from './Game/BottomSheets/Congratulations'
import Congratulations2 from './Game/BottomSheets/Congratulations2'
import Congratulations3 from './Game/BottomSheets/Congratulations3'
// import { useSelector, useDispatch } from 'react-redux'
import Promotion from './Game/BottomSheets/Promotion'

function App() {
  const JoinCountRedux = useSelector((state) => state.Game.JoinCountRedux)
  const ClaimRewardCall = useSelector((state) => state.Game.ClaimRewardCall)
  const PromotionBottomSheet = useSelector((state) => state.Game.PromotionBottomSheet)

  const [Mode, setMode] = useState('light')
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    // console.log(mediaQuery.matches)
    if (mediaQuery.matches) {
      setMode('dark')
    }
  }, [])

  let AuthState = GetCookie('Token')
  const history = useHistory();

  useEffect(() => {
    if (AuthState == null || AuthState == 'null') {
      // history.push(`/`);
    }
  }, [AuthState])


  useEffect(() => {
    StartGame()
    StartTimer()
    console.log("start")
  }, [AuthState])
  const StartGame = async () => {
    if (AuthState == null || AuthState == 'null') {
    } else {
      const CheckDataBaseGameClass = new CheckDataBaseGame();
      await CheckDataBaseGameClass.CheckDataBase(dispatch)
    }
  }
  const intervalRef1 = useRef(true);
  useEffect(() => {
    // console.log(intervalRef1)
    if (intervalRef1.current != null) {
      intervalRef1.current = setInterval(() => {
        StartTimer()
        // clearInterval(intervalRef1.current);
      }, 1000);
    }

    // return () => {
    //   clearInterval(intervalRef1.current);
    // };
  }, [intervalRef1]);
  const StartTimer = async () => {
    const CheckDataBaseGameClass = new CheckDataBaseGame();
    await CheckDataBaseGameClass.TimerLeague(dispatch)
  }
  const dispatch = useDispatch()



  const intervalJoin = useRef(true);
  useEffect(() => {

    if (intervalJoin.current != null) {
      intervalJoin.current = setInterval(() => {
        CheckClaim()
        clearInterval(intervalJoin.current);
      }, 1000);
    }
  
    return () => {
      clearInterval(intervalJoin.current);
    };

  }, [JoinCountRedux])
  const CheckClaim = async () => {
    const CheckDataBaseGameClass = new CheckDataBaseGame();
    await CheckDataBaseGameClass.CheckClaimReward(dispatch, JoinCountRedux)
  }
  useEffect(() => {
    // console.log(ClaimRewardCall)
    if (ClaimRewardCall == true) {
      RequestForDataAfterClaim()
    }
    return () => {

    }
  }, [ClaimRewardCall])
  const RequestForDataAfterClaim = async () => {
    // console.log('request for data after claim reward')
    const CheckDataBaseGameClass = new CheckDataBaseGame();
    await CheckDataBaseGameClass.CollectDataAfterClaim()


  }
  return (
    <div className={"App " + Mode} >
      <Promotion />
      <Congratulations />
          <Congratulations2 />
          <Congratulations3 />
      <Router>
        <Switch>

          <Route exact path="/" component={Auth} />
          <Route path="/Login" component={Login} />
          <Route path="/Register" component={Register} />
          <Route path="/RegisterGetPassword" component={RegisterGetPassword} />
          <Route path="/Home" component={Home} />
          <Route path="/GetPhoneNumber" component={GetPhoneNumber} />
          <Route path="/CheckVerificationCode" component={CheckVerificationCode} />
          <Route path="/ForgetPassword" component={ForgetPassword} />
          <Route path="/ForgetPasswordGetCode" component={ForgetPasswordGetCode} />
          <Route path="/ChangePassword" component={ChangePassword} />
          <Route path="/Coin" component={Coin} />
          <Route path="/LoginTelegram" component={LoginTelegram} />
          <Route path="/VerifyCode" component={VerifyCode} />
          <Route path="/JoinBot" component={JoinBot} />
          <Route path="/AutoManualJoin" component={AutoManualJoin} />
          <Route path="/Notice" component={Notice} />
          <Route path="/GetLinkUsername" component={GetLinkUsername} />
          <Route path="/GetUsernameView" component={GetUsernameView} />

          <Route path="/TransferHistory" component={TransferHistory} />
          <Route path="/GiftCode" component={GiftCode} />
          <Route path="/Profile" component={Profile} />
          <Route path="/AccountInformation" component={AccountInformation} />
          <Route path="/Payments" component={Payments} />
          <Route path="/LogOut" component={LogOut} />
          <Route path="/DeleteAccount" component={DeleteAccount} />
          <Route path="/AddNumber" component={AddNumber} />
          <Route path="/AddNumberVerify" component={AddNumberVerify} />
          <Route path="/EditNumber" component={EditNumber} />
          <Route path="/EditNumberVerify" component={EditNumberVerify} />
          <Route path="/EditProfile" component={EditProfile} />
          <Route path="/SetName" component={SetName} />


          <Route path="/Orders" component={Orders} />
          <Route path="/OrderDetailsSingle" component={OrderDetailsSingle} />
          <Route path="/OrderDetailsAll" component={OrderDetailsAll} />
          <Route path="/Privacy" component={Privacy} />
          <Route path="/SetPassword" component={SetPassword} />
          <Route path="/GetCheckPassword" component={GetCheckPassword} />
          <Route path="/GetChangePassword" component={GetChangePassword} />
          <Route path="/Game" component={Game} />
          <Route path="/AllReward" component={AllReward} />


          <Route path="/SellingPhoneNumbers" component={SellingPhoneNumbers} />
          <Route path="/SaleHistory" component={SaleHistory} />
          <Route path="/VerificationCode" component={VerificationCode} />


        </Switch>
      </Router>
      <Update />
      <SnackBarEndLeague />
    </div>
  );
}

export default App;
