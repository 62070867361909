import React, { useEffect, useState, useRef } from 'react'
import './Game.css'
import DoneIcon from '@mui/icons-material/Done';

import BottomNavigation from './../Home/BottomNavigation'
import MyLeagueComponent from './MyLeague/MyLeagueComponent';
import TopJoinerComponent from './TopJoiner/TopJoinerComponent';
import HeaderProgressBar from './HeaderProgressBar'
import GetName from './BottomSheets/GetName'
import { useSelector, useDispatch } from 'react-redux'


import { AllApi } from '../Api/AllApi'

import NoInternet1 from './../Public/Image/Join/NoInternet.svg'
import { CheckDataBaseGame } from './../GameAction/CheckDataBaseGame'

import {
    SetAppSettingData,
    SetUserLeagueData,
    SetMyLeagueRank,
    SetLeagueLimitJoinProgress,
    SetLeagueMinimumJoinProgress,
    SetRewardLeague,
    SetMyLeagueRankNumber,
    SetGiftTopjoin,
    SetNameAccount,
    SetJoinCountRedux,
    SetPromotionBottomSheet,
    SetUserReward,
    SetClaimRewardCall,
    SetDataClaimReward,
    SetXpLimit,
    SetXpCount,
    SetLevelUserHeaderProgress,
    SetSnackBarEndLeagueState
} from './../app/Game'


import { GameDB } from './../DataBase/GameDB'
import Snackbar from '@mui/material/Snackbar';

import Loading from './../Loading'
import { OnlineRequestAppSettingGame } from '../GameAction/OnlineRequestAppSettingGame'
import { OnlineRequestProfileGame } from '../GameAction/OnlineRequestProfileGame'
import { OnlineRequestLeagueListGame } from '../GameAction/OnlineRequestLeagueListGame'
import { OnlineRequestTopJoinerGame } from '../GameAction/OnlineRequestTopJoinerGame'

const Game = () => {
    const dispatch = useDispatch()
    const [MyLeague, setMyLeague] = useState(true)
    const JoinCountRedux = useSelector((state) => state.Game.JoinCountRedux)
    const UserLeagueData = useSelector((state) => state.Game.UserLeagueData)
    const ClaimRewardCall = useSelector((state) => state.Game.ClaimRewardCall)
    const MyLeagueRank = useSelector((state) => state.Game.MyLeagueRank)
    const TopJoinerTime = useSelector((state) => state.Game.TopJoinerTime)
    const IsBetweenLeaguge = useSelector((state) => state.Game.IsBetweenLeaguge)

    const [LoadState, setLoadState] = useState(false)
    const [LastUpdate, setLastUpdate] = useState(0)
    const [NoInternet, setNoInternet] = useState(false)
    // const [IsBetweenLeaguge, setIsBetweenLeaguge] = useState(null)
    const [runInterval, setRunInterval] = useState(false);
    const [LastTime, setLastTime] = useState(0);
    const [CashTime, setCashTime] = useState(0);
    const intervalRef = useRef(null);

    useEffect(() => {
        // console.log(window.navigator.onLine)
        if (NoInternet == true) {

            const timer = setInterval(async () => {
                let IsOnline = window.navigator.onLine
                // console.log(IsOnline)
                if (IsOnline == true) {
                    clearInterval(timer);
                    StartGame()

                }
            }, 10000);
            // console.log(NoInternet)
        }
    }, [NoInternet])


    useEffect(() => {

        if (runInterval) {
            intervalRef.current = setInterval(() => {
                let NowInNow = new Date().getTime();
                let LastTimeLeague = localStorage.getItem('LastTimeLeague')
                let Distance = LastTimeLeague - NowInNow;
                // var minutescashTime = Math.floor(CashTime / 60);
                var minutes = Math.floor((Distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((Distance % (1000 * 60)) / 1000);
                // console.log(minutes, seconds)

                if (minutes < 0) {
                    setLastUpdate(Math.abs(minutes))
                } else {
                    setLastUpdate(false)

                }
            }, 1000);
        }

        return () => {
            clearInterval(intervalRef.current);
        }; // clear interval when unmounting the component
    }, [runInterval, LastTime, CashTime]);

    // SnackBar /////////////////////////
    const [state, setState] = React.useState({
        opens: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, opens } = state;
    const handleClick = (newState) => {
        setState({ ...newState, opens: true });
    };
    const handleClose = () => {
        setState({ ...state, opens: false });
    };
    const [open, setOpen] = useState(false)

    function onDismiss() {
        setTimeout(() => {
            setOpen(false)
        }, 300);
    }
    // SnackBar//////////////////////////////

    const GetOnlineRequest = async () => {
        const OnlineRequestLeagueListGameClass = new OnlineRequestLeagueListGame();
        const OnlineRequestProfileGameClass = new OnlineRequestProfileGame();
        const OnlineRequestTopJoinerGameClass = new OnlineRequestTopJoinerGame();

        let res = await OnlineRequestLeagueListGameClass.OnlineRequestLeagueList()
        await OnlineRequestProfileGameClass.OnlineRequestProfile()
        await OnlineRequestTopJoinerGameClass.OnlineRequestTopJoiner()
        // console.log(res)
        OfflineRequestAppSetting()
        OfflineRequestProfile()
        OfflineRequestLeagueList()
        dispatch(SetClaimRewardCall(false))
        console.log('first')
    }
    useEffect(() => {

        // console.log(ClaimRewardCall)
        if (ClaimRewardCall == true) {
            GetOnlineRequest()
        }

        if (LastUpdate >= 1) {
            StartGame()
        }
    }, [ClaimRewardCall])

    const OfflineRequestAppSetting = async () => {
        // console.log('OfflineRequestAppSetting')
        let AppSetting = await GameDB.AppSetting.toArray()
        if (AppSetting.length > 0) {
            // console.log(AppSetting[0])
            // console.log(AppSetting[0].AppSetting.leagues)


        } else {
            // console.log('error app')
            // setNoInternet(true)

            const timer = setInterval(async () => {
                AppSetting = await GameDB.AppSetting.toArray()
                // console.log(AppSetting)
                if (AppSetting.length == 1) {
                    clearInterval(timer);
                    OfflineRequestAppSetting()
                } else {
                    // console.log('error again !!!')
                }

            }, 1000);
        }


    }
    const OfflineRequestProfile = async () => {
        // console.log('OfflineRequestProfile')
        let Profile = await GameDB.Profile.toArray()
        // console.log(Profile)
        // console.log(Profile.length)
        if (Profile.length > 0) {
            // console.log(Profile[0])
            // dispatch(SetNameAccount(Profile[0].Profile.nameAccount))
            dispatch(SetJoinCountRedux(Profile[0].Profile.joinCount))

        } else {
            // console.log('error')
            // setNoInternet(true)
            const timer = setInterval(async () => {
                Profile = await GameDB.Profile.toArray()
                // console.log(AppSetting)
                if (Profile.length == 1) {
                    clearInterval(timer);
                    OfflineRequestProfile()
                } else {
                    // console.log('error again !!!')
                }

            }, 1000);
        }

    }

    const OfflineRequestLeagueList = async () => {
        // console.log('OfflineRequestLeagueList')

        let LeagueList = await GameDB.LeagueList.toArray()
        if (LeagueList.length > 0) {
            // console.log(LeagueList[0])
            dispatch(SetUserLeagueData(LeagueList[0].LeagueList.data))
            dispatch(SetLevelUserHeaderProgress(LeagueList[0].LeagueList))
            let LevelUser = false
            LevelUser = LeagueList[0].LeagueList.data.userInformation.leagueLevel

            let ResOfCalc = await GetUserDataFromAppSetting(LevelUser)


            let cashTime = LeagueList[0].LeagueList.data.cashTime
            let LastTime = LeagueList[0].LastTime
            clearInterval(intervalRef.current);
            localStorage.setItem('LastTimeLeague', LastTime)
            setLastTime(LastTime)
            setCashTime(cashTime)
            setRunInterval(true)
        } else {
            // console.log('error')
            // setNoInternet(true)
            const timer = setInterval(async () => {
                LeagueList = await GameDB.LeagueList.toArray()
                // console.log(LeagueList)
                if (LeagueList.length == 1) {
                    clearInterval(timer);
                    OfflineRequestLeagueList()
                    // setNoInternet(false)
                } else {
                    // console.log('error again !!!')
                }

            }, 1000);

        }

    }
    const GetUserDataFromAppSetting = async (LevelUser) => {
        let AppSetting = await GameDB.AppSetting.toArray()
        // console.log(AppSetting)
        if (AppSetting.length == 1) {
            // console.log(1)
            // console.log(AppSetting[0].AppSetting)
            dispatch(SetAppSettingData(AppSetting[0].AppSetting))
            let RewardArray = AppSetting[0].AppSetting.leagues

            if (LevelUser != false) {
                RewardArray.map(item => {

                    if (item.leagueLevel == LevelUser) {

                        dispatch(SetRewardLeague(item))
                    }
                })
                dispatch(SetGiftTopjoin(AppSetting[0].AppSetting.giftTopjoin))

                let LeaguesArray = AppSetting[0].AppSetting.leagues
                LeaguesArray.map(item => {
                    if (item.leagueLevel == LevelUser) {
                        dispatch(SetMyLeagueRank(item.leagueName))
                        dispatch(SetLeagueLimitJoinProgress(item.max))
                        dispatch(SetLeagueMinimumJoinProgress(item.min))

                        dispatch(SetMyLeagueRankNumber(item.leagueLevel))

                    }
                })
            }
        } else {
            // console.log(2)
            const timer = setInterval(async () => {
                AppSetting = await GameDB.AppSetting.toArray()
                // console.log(AppSetting)
                if (AppSetting.length == 1) {
                    clearInterval(timer);
                    GetUserDataFromAppSetting(LevelUser)
                } else {
                    // console.log(147852)
                }

            }, 1000);

        }


    }
    const GetleagueListJoinsfunc = async () => {
        let Data;
        const MakeApiClass = new AllApi();
        let Result = await MakeApiClass.GetleagueListJoins()
        // console.log(Result)

        if (Result.code == 200) {
            Data = Result

        } else if (Result.code == 301) {
            Data = 'Name'

        } else if (Result.code == 999) {
            Data = 'Network'
        }
        return Data
    }
    // const CheckForClaimReward = async () => {

    //     let JR = JoinCountRedux
    //     // let JR = 150
    //     let AppSetting = await GameDB.AppSetting.toArray()
    //     let Profile = await GameDB.Profile.toArray()

    //     let LeagueLevelProfile
    //     if (Profile.length > 0) {

    //         LeagueLevelProfile = Profile[0].Profile.leagueLevel
    //     }
    //     if (AppSetting.length > 0) {

    //         let LeaguesAppSetting = AppSetting[0].AppSetting.leagues
    //         // console.log(LeaguesAppSetting)
    //         LeaguesAppSetting.map((item, i) => {
    //             let min = item.min
    //             let max = item.max ? item.max : false
    //             let PrevIndex
    //             if (LeagueLevelProfile == item.leagueLevel) { PrevIndex = i }

    //             if (min <= JR && JR < max) {

    //                 if (LeagueLevelProfile == item.leagueLevel) {
    //                     // no need to claim reward
    //                 } else {
    //                     // need to claim reward
    //                     if (item.leagueLevel - LeagueLevelProfile > 0) {
    //                         let Comparelevel = item.leagueLevel - LeagueLevelProfile
    //                         dispatch(SetUserReward(item))
    //                         let dataClaim = { prevLeague: LeagueLevelProfile, TargetLeague: item.leagueLevel, LeaguesAppSetting }

    //                         dispatch(SetDataClaimReward(dataClaim))
    //                         dispatch(SetPromotionBottomSheet(true))
    //                     }

    //                 }
    //             } else if (min <= JR && max == false) {
    //                 if (LeagueLevelProfile == item.leagueLevel) {
    //                     // no need to claim reward
    //                 } else {
    //                     // need to claim reward
    //                     if (item.leagueLevel - LeagueLevelProfile > 0) {
    //                         let Comparelevel = item.leagueLevel - LeagueLevelProfile
    //                         dispatch(SetUserReward(item))
    //                         let dataClaim = { prevLeague: LeagueLevelProfile, TargetLeague: item.leagueLevel, LeaguesAppSetting }
    //                         dispatch(SetDataClaimReward(dataClaim))
    //                         dispatch(SetPromotionBottomSheet(true))
    //                     }

    //                 }
    //             }
    //         })


    //     }
    // }
    // useEffect(() => {
    //     CheckForClaimReward()
    // }, [JoinCountRedux])


    const StartGame = async () => {
        const CheckDataBaseGameClass = new CheckDataBaseGame();
        await CheckDataBaseGameClass.CheckDataBase()
    }



    useEffect(() => {
        OfflineRequestAppSetting()
        OfflineRequestProfile()
        OfflineRequestLeagueList()

        return () => {

        }
    }, [])


    // Check Times AppSetting/////////////////////
    // const [Time, setTime] = useState('')
    // const TimerAppSetting = async () => {
    //     // let curTime = Date.now();

    //     let LeagueList = await GameDB.LeagueList.toArray()


    //     if (LeagueList[0] != undefined) {
    //         let secondsData = LeagueList[0].LeagueList.data.timeFinishLeague
    //         //  secondsData = 20   
    //         if (secondsData >= 0) {
    //             setIsBetweenLeaguge(false)

    //             dispatch(SetSnackBarEndLeagueState(false))
    //             GameDB.LeagueList.toArray(function (LeagueListItem) {
    //                 if (LeagueListItem.length > 0) {
    //                     GameDB.LeagueList.toCollection().modify(LeagueListItem => {
    //                         LeagueListItem.LeagueList.data.timeFinishLeague = secondsData - 1;
    //                     });
    //                 }
    //             });
    //             const days = Math.floor(secondsData / 86400);
    //             const hours = Math.floor((secondsData % 86400) / 3600);
    //             const minutes = Math.floor((secondsData % 3600) / 60);
    //             const secondsLeft = secondsData % 60;
    //             let m, h, s, day;
    //             m = String(minutes).padStart(2, "0");
    //             h = String(hours).padStart(2, "0");
    //             s = String(secondsLeft).padStart(2, "0");
    //             day = String(days).padStart(2, "0");
    //             setTime({
    //                 days: day,
    //                 hours: h,
    //                 minutes: m,
    //                 seconds: s,
    //             })
    //         } else if (secondsData <= 0) {
    //             setLoadState(true)
    //             // setIsBetweenLeaguge(true)
    //             let GetleagueListJoinsRes = await GetleagueListJoinsfunc()
    //             console.log(GetleagueListJoinsRes)
    //             if (GetleagueListJoinsRes != 'Network') {
    //                 GameDB.LeagueList.toArray(function (LeagueListItem) {
    //                     if (LeagueListItem.length > 0) {
    //                         GameDB.LeagueList.toCollection().modify(LeagueListItem => {
    //                             LeagueListItem.LeagueList = GetleagueListJoinsRes.data;
    //                         });
    //                     }
    //                 });
    //             }

    //             let curTime = Date.now();
    //             let CloseSnackBarEndLeagueState = localStorage.getItem('CloseSnackBarEndLeagueState')
    //             if (curTime + 3600000 < CloseSnackBarEndLeagueState || CloseSnackBarEndLeagueState == null) {
    //                 dispatch(SetSnackBarEndLeagueState(true))
    //             }

    //             // setTime({
    //             //     days: '00',
    //             //     hours: '00',
    //             //     minutes: '00',
    //             //     seconds: '00',
    //             // })
    //             setLoadState(false)

    //         }

    //     } else {
    //         console.log('No Data')
    //     }


    // }
    // const intervalRef1 = useRef(true);
    // useEffect(() => {
    //     // console.log(intervalRef1)
    //     if (intervalRef1.current != null) {
    //         intervalRef1.current = setInterval(() => {
    //             // TimerAppSetting()
    //             // clearInterval(intervalRef1.current);
    //         }, 1000);
    //     }

    //     return () => {
    //         clearInterval(intervalRef1.current);
    //     };
    // }, [intervalRef1]);
    // Check Times AppSetting////////////////////
    //    console.log(LoadState,NoInternet,IsBetweenLeaguge)
    //    console.log(UserLeagueData)
    return (
        <>

            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={opens}
                onClose={handleClose}
                onDismiss={onDismiss}
                message="Something went wrong"
                key={vertical + horizontal}
                autoHideDuration={3000}
            />
            {
                !LoadState ?
                    NoInternet ?
                        <>
                            <div className='JoinContainer'>
                                <div className='NoInterNetLogo'>
                                    <img src={NoInternet1} />
                                </div>
                                <div className='NoInterNetText'>
                                    Please make sure your device is connected to the internet
                                </div>
                                <div className='NoInterNetButton'>
                                    <button onClick={() => window.location.reload()}>
                                        Retry
                                    </button>
                                </div>

                            </div>
                        </>

                        :
                        IsBetweenLeaguge != null ?
                            UserLeagueData.topUsers ?
                                <div className='Game'>
                                    <div className='GameHeader'>
                                        <div className='GameHeaderInner'>
                                            <div>
                                                Game
                                            </div>
                                            <div className='GameHeaderProgress' >
                                                <HeaderProgressBar />
                                            </div>

                                        </div>

                                    </div>

                                    <div className='GameContent'>

                                        {
                                            // !IsBetweenLeaguge ?
                                            <div className='GameChips'>
                                                <div className={MyLeague ? 'GameChipsItemLeft noSelect ActiveChips' : 'GameChipsItemLeft noSelect'} onClick={() => setMyLeague(true)}>
                                                    {MyLeague ? <span><DoneIcon className={'GameChipsDone'} /></span> : null}
                                                    My League
                                                </div>
                                                <div className={!MyLeague ? 'GameChipsItemRight noSelect ActiveChips' : 'GameChipsItemRight noSelect'} onClick={() => setMyLeague(false)}>
                                                    {!MyLeague ? <span><DoneIcon className={'GameChipsDone'} /></span> : null}

                                                    Top Joiners
                                                </div>
                                            </div>
                                            // : null
                                        }


                                        <div className='SelectComponent'>
                                            {

                                                MyLeague ?

                                                    <MyLeagueComponent MyLeagueRank={MyLeagueRank} LastUpdate={LastUpdate} />
                                                    :
                                                    <TopJoinerComponent IsBetweenLeaguge={IsBetweenLeaguge} Time={TopJoinerTime} />
                                            }




                                        </div>
                                        <GetName />
                                    </div>
                                    <div className='GameFooter'>

                                        <BottomNavigation />

                                    </div>
                                </div>

                                : <Loading /> :
                            <Loading />
                    : <Loading />

            }

        </>
    )
}


export default Game