import React from 'react'
import { AllApi } from '../Api/AllApi'
import { GameDB } from './../DataBase/GameDB'

export class OnlineRequestTopJoinerGame extends React.Component {
    constructor() {
        super();

    }
    async GetAllListJoinsfunc () {
        let Data;
        const MakeApiClass = new AllApi();
        let Result = await MakeApiClass.GetAllListJoins()
        // console.log(Result)

        if (Result.code == 200) {
            Data = Result

        } else if (Result.code == 301) {
            Data = 'Name'

        } else if (Result.code == 999) {
            Data = 'Network'
        }
        return Data
    }
    async OnlineRequestTopJoiner() {


        let TopJoinerRes = await this.GetAllListJoinsfunc()

        if (TopJoinerRes != 'Network') {
            let LevelUser = false
            LevelUser = TopJoinerRes.data.data.userInformation.leagueLevel


            // let ResOfCalc = await GetUserDataFromAppSetting(LevelUser)

            let CashTime1 = TopJoinerRes.data.data.cashTime
   
            let NowInNow = new Date().getTime();
            let LastTime = CashTime1 * 1000 + NowInNow;
       
            GameDB.TopJoiner.toArray(function (TopJoinerItem) {
                if (TopJoinerItem.length > 0) {
                    GameDB.TopJoiner.toCollection().modify(App => {
                        App.LastTime = LastTime;
                        // App.LastUpdate = NowInNow
                        App.TopJoiner = TopJoinerRes.data.data.topUsers
                        App.userInformation = TopJoinerRes.data.data.userInformation
                    });
                } else {
                    GameDB.TopJoiner.add({ TopJoiner: TopJoinerRes.data.data.topUsers, userInformation: TopJoinerRes.data.data.userInformation, LastTime, LastUpdate: NowInNow });
                }

            });
        } else {

            // console.log('OfflineRequestLeagueList')
            // handleClick({ vertical: 'bottom', horizontal: 'center' })
            // OfflineRequestTopJoiner()


        }
        return TopJoinerRes
    }


}
