import React from 'react'
import { AllApi } from '../Api/AllApi'
import { GameDB } from './../DataBase/GameDB'


export class OnlineRequestProfileGame extends React.Component {
    
    constructor() {
        super();
        // this.dispatch = useDispatch()
    }
    async GetProfile() {
        let Data;
        const MakeApiClass = new AllApi();
        let Result = await MakeApiClass.GetProfile()
        // console.log(Result)
        if (Result.code == 200) {
            Data = Result
        } else if (Result.code == 999) {
            Data = 'Network'
        }
        // console.log(Data)
        return Data
    }
    async OnlineRequestProfile() {


        let GetProfileRes = await this.GetProfile()
    
        if (GetProfileRes != 'Network') {
            let CashTime = GetProfileRes.data.cashTime

            let LevelUser = false
            LevelUser = GetProfileRes.data.user.leagueLevel

            let NowInNow = new Date().getTime();
            let LastTime = CashTime * 1000 + NowInNow;
            GameDB.Profile.toArray(function (ProfileItem) {
                if (ProfileItem.length > 0) {
                    GameDB.Profile.toCollection().modify(ProfileItem => {
                        ProfileItem.LastTime = LastTime;
                        ProfileItem.Profile = GetProfileRes.data.user

                    });
                } else {
                    GameDB.Profile.add({ Profile: GetProfileRes.data.user, LastTime, LastUpdate: NowInNow });
                }

            });
        } else {
            // handleClick({ vertical: 'bottom', horizontal: 'center' })
            // OfflineRequestProfile()
        }
        return GetProfileRes
    }
 

}
