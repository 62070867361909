import React from 'react';
import axios from 'axios';
import cryptlib from "cryptlib"
import { GetCookie } from '../Action/GetCookie'
// import { useQuery } from '@tanstack/react-query';
export class AllApi extends React.Component {


    constructor() {

        super();
        this.Token = GetCookie('Token')
        this.iv = '#GV^%^SUR&T*#Y*4'
        this.key = cryptlib.getHashSha256('#GV^%^SUR&T*#Y*4', 32);
        this.VersionCode = 22051377
      
        // this.VersionCode=31195
    }

    async ClaimReward() {

        try {
            const user = { data: {} }
            let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
            let result = await axios.post(`https://api.membersgram.com/main/v1/game/claimReward`,
                {
                    "data": data
                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        // market: 'google',
                        market: 'WebAppP',
                        'Content-Type': 'application/json',
                        mainapitoken: this.Token
                    }
                },
            );
            let mydata = result.data
            let resultGetMember = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            return (resultGetMember)

        } catch (error) {
            return ({ message: `err in GetleagueListJoins Api : ${error}`, code: 999 })
        }
    }
    
    async SetNameAccount(name) {

        try {
            const user = { data: { "nameAccount": name } }
            let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
            let result = await axios.post(`https://api.membersgram.com/main/v1/game/setNameAccount`,
                {
                    "data": data
                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        // market: 'google',
                        market: 'WebApp',
                        'Content-Type': 'application/json',
                        mainapitoken: this.Token
                    }
                },
            );
            let mydata = result.data
            let resultGetMember = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            return (resultGetMember)

        } catch (error) {
            return ({ message: `err in GetleagueListJoins Api : ${error}`, code: 999 })
        }
    }

    async GetAllListJoins() {

        try {
            const user = { data: {} }
            let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
            let result = await axios.post(`https://api.membersgram.com/main/v1/game/getAllListJoins`,
                {
                    "data": data
                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        // market: 'google',
                        market: 'WebAppP',
                        'Content-Type': 'application/json',
                        mainapitoken: this.Token
                    }
                },
            );
            let mydata = result.data
            let resultGetMember = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            return (resultGetMember)

        } catch (error) {
            return ({ message: `err in GetleagueListJoins Api : ${error}`, code: 999 })
        }
    }

    async GetleagueListJoins() {

        try {
            const user = { data: {} }
            let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
            let result = await axios.post(`https://api.membersgram.com/main/v1/game/getleagueListJoins`,
                {
                    "data": data
                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        // market: 'google',
                        market: 'WebAppP',
                        'Content-Type': 'application/json',
                        mainapitoken: this.Token
                    }
                },
            );
            let mydata = result.data
            let resultGetMember = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            return (resultGetMember)

        } catch (error) {
            return ({ message: `err in GetleagueListJoins Api : ${error}`, code: 999 })
        }
    }

    async appSetting() {

        try {
            const user = { data: {} }
            let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
            let result = await axios.post(`https://api.membersgram.com/main/v1/appSetting`,
                {
                    "data": data
                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        // market: 'google',
                        market: 'WebAppP',
                        'Content-Type': 'application/json',
                        mainapitoken: this.Token
                    }
                },
            );
            let mydata = result.data
            let resultGetMember = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            return (resultGetMember)

        } catch (error) {
            return ({ message: `err in appSetting Api : ${error}`, code: 999 })
        }
    }
    async GetMember(IsIranian) {
        let Market = IsIranian ? "WebAppZ" : "WebAppP"
        const user = { data: {} }
        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);

        try {

            let result = await axios.post(`https://api.membersgram.com/main/v1/getmembers`,
                {
                    "data": data
                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        // market: 'google',
                        market: Market,
                        'Content-Type': 'application/json',
                        mainapitoken: this.Token
                    }
                },
            );
            let mydata = result.data
            let resultGetMember = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            return (resultGetMember)

        } catch (error) {
            return ({ message: `err in GetMember Api : ${error}`, code: 999 })
        }
    }
    async GetView(IsIranian) {
        // console.log(IsIranian) 
        let Market = IsIranian == true ? "zarinpal" : "google"
        const user = { data: {} }
        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
        // console.log(Market)
        try {

            let result = await axios.post(`https://api.membersgram.com/main/v1/view/getViews`,
                {
                    "data": data

                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: Market,
                        'Content-Type': 'application/json',
                        mainapitoken: this.Token

                    }
                },
            );

            let mydata = result.data
            let resultGetView = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            // console.log(resultGetView)
            return (resultGetView)

        } catch (error) {
            return (`err in GetView Api : ${error}`)
        }
    }
    async ChangePasswordApi(email, k, password) {

        const user = { data: { email, key: k, password } }
        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);

        try {

            let result = await axios.post(`https://api.membersgram.com/main/v1/auth/changePassword/change`,
                {
                    "data": data

                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'Content-Type': 'application/json',

                    }
                },
            );

            let mydata = result.data
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            return (resultRegister)

        } catch (error) {
            return (`err in ChangePasswordApi : ${error}`)
        }
    }
    async CheckChannelBeforePurchase(token, username) {

        const user = { data: { username } }
        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);

        try {

            let result = await axios.post(`https://api.membersgram.com/main/v1/checkChannelBeforePurchase`,
                {
                    "data": data

                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'Content-Type': 'application/json',
                        "mainapitoken": token
                    }
                },
            );

            let mydata = result.data
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            // console.log(resultRegister)

            return (resultRegister)

        } catch (error) {
            return (`err in CheckChannelBeforePurchase : ${error}`)
        }
    }
    async CheckEmailApi(email) {


        const iv = '#GV^%^SUR&T*#Y*4'
        const key = cryptlib.getHashSha256('#GV^%^SUR&T*#Y*4', 32);
        const user = { data: { email } }
        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);

        try {

            let result = await axios.post(`https://api.membersgram.com/main/v1/auth/checkemail`,
                {
                    "data": data

                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'Content-Type': 'application/json',

                    }
                },
            );

            let mydata = result.data

            // console.log(mydata)
            var iv1 = '#GV^%^SUR&T*#Y*4';
            var key1 = cryptlib.getHashSha256(iv1, 32)
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            // console.log(resultRegister)

            return (resultRegister)

        } catch (error) {
            return (`err in Register : ${error}`)
        }
    }
    async CheckVerificationCodeApi(Token, Code) {

        const user = { data: { "verifycode": Code } }
        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
        try {

            let result = await axios.post(`https://api.membersgram.com/main/v1/auth/verify`,
                {
                    "data": data

                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'Content-Type': 'application/json',
                        "mainapitoken": Token

                    }
                },
            );

            let mydata = result.data
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            return (resultRegister)

        } catch (error) {
            return (`err in Register : ${error}`)
        }
    }
    async ForgetPasswordApi(email) {


        const user = { data: { email } }
        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);

        try {

            let result = await axios.post(`https://api.membersgram.com/main/v1/auth/changePassword`,
                {
                    "data": data

                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'Content-Type': 'application/json',

                    }
                },
            );

            let mydata = result.data


            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            // console.log(resultRegister)

            return (resultRegister)

        } catch (error) {
            return (`err in ForgetPasswordApi : ${error}`)
        }
    }
    async ForgetPasswordGetCodeApi(email, verifycode) {


        const user = { data: { email, verifycode } }
        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);

        try {

            let result = await axios.post(`https://api.membersgram.com/main/v1/auth/changePassword/verify`,
                {
                    "data": data

                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'Content-Type': 'application/json',

                    }
                },
            );

            let mydata = result.data


            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            // console.log(resultRegister)

            return (resultRegister)

        } catch (error) {
            return (`err in ForgetPasswordGetCodeApi : ${error}`)
        }
    }
    async GetProfile(token) {

        const user = { data: {} }
        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);

        try {

            let result = await axios.post(`https://api.membersgram.com/main/v1/getprofile`,
                {
                    "data": data

                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'Content-Type': 'application/json',
                        mainapitoken: this.Token
                    }
                },
            );

            let mydata = result.data

            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            // console.log(resultRegister)

            return (resultRegister)

        } catch (error) {
            return ({ message: `err in GetleagueListJoins Api : ${error}`, code: 999 })
        }
    }
    async LoginApi(email, password, ClientUser) {
        let client = ClientUser ? ClientUser : null

        const user = { data: { email, password, client } }
        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
        try {

            let result = await axios.post(`https://api.membersgram.com/main/v1/auth/login`,
                {
                    "data": data

                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'Content-Type': 'application/json',

                    }
                },
            );

            let mydata = result.data
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            return (resultRegister)

        } catch (error) {
            return (`err in Login : ${error}`)
        }
    }
    async LoginWithGoogle(Token, needPhonenumber, ClientUser) {
        let client = ClientUser ? ClientUser : null


        const user = { data: { code: Token, market: 'WebAppP', needPhonenumber, client } }
        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
        // console.log(Token)
        try {

            // let result = await axios.post(`https://api.membersgram.com/main/v1/auth/google/loginWebapp`,
            let result = await axios.post(`https://api.membersgram.com/main/v1/auth/google/newloginWebapp?code=${Token}`,
                {
                    "data": data

                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'Content-Type': 'application/json',

                    }
                },
            );

            let mydata = result.data
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            return (resultRegister)

        } catch (error) {
            return (`err in LoginWithGoogle : ${error}`)
        }
    }
    async RegisterApi(email, password, needPhonenumber, market, ClientUser) {
        let client = ClientUser ? ClientUser : null
        const user = { data: { email, password, needPhonenumber, market, client } }
        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
        try {

            let result = await axios.post(`https://api.membersgram.com/main/v1/auth/register`,
                {
                    "data": data

                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'Content-Type': 'application/json',

                    }
                },
            );

            let mydata = result.data
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            return (resultRegister)

        } catch (error) {
            return (`err in Register : ${error}`)
        }
    }
    async SaveViewByCoin(user) {

        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);

        try {

            let result = await axios.post(`https://api.membersgram.com/main/v1/view/saveViewByCoin`,
                {
                    "data": data

                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'Content-Type': 'application/json',
                        "mainapitoken": this.Token

                    }
                },
            );

            let mydata = result.data
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            return (resultRegister)

        } catch (error) {
            return (`err in SaveViewByCoin : ${error}`)
        }
    }
    async SendVerificationCodeApi(Token, FullPhoneNumber) {

        const user = { data: { "phonenumber": FullPhoneNumber } }
        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
        try {

            let result = await axios.post(`https://api.membersgram.com/main/v1/auth/addPhonenumber`,
                {
                    "data": data

                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'Content-Type': 'application/json',
                        "mainapitoken": Token

                    }
                },
            );

            let mydata = result.data


            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))

            return (resultRegister)

        } catch (error) {
            return (`err in SendVerificationCodeApi : ${error}`)
        }
    }
    async StoreChannelByCoin(_id, UserNameForOrder) {




        const user = {
            data: {
                // "phonenumber": "989303741690",
                // "email": 'majidpnx@gmail.com',
                "_id": _id, // بسته _id
                // "sku": sku,
                // "price": price,
                "username": UserNameForOrder,
                //    "title": "STOPELON20OFFICIAL"
            }
        }
        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);

        try {

            let result = await axios.post(`https://api.membersgram.com/main/v1/storechannelbycoin`,
                {
                    "data": data

                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'Content-Type': 'application/json',
                        "mainapitoken": this.Token
                    }
                },
            );

            let mydata = result.data


            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            // console.log(resultRegister)

            return (resultRegister)

        } catch (error) {
            return (`err in StoreChannelByCoin : ${error}`)
        }
    }
    async ChangeShop() {

        const user = { data: { shop_type: 2 } }
        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);

        try {

            let result = await axios.post(`https://api.membersgram.com/main/v1/changeshop`,
                {
                    "data": data

                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'mainapitoken': this.Token,
                        'Content-Type': 'application/json',

                    }
                },
            );

            let mydata = result.data


            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            // console.log(resultRegister)

            return (resultRegister)

        } catch (error) {
            return (`err in GetCoinBuy : ${error}`)
        }
    }
    async GetChannels(apitoken, token) {


        const user = { data: {} }
        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
        //  console.log(apitoken)
        try {

            let result = await axios.post(`https://api.membersgram.com/main/v2/getchannels`,
                {
                    "data": data

                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'mainapitoken': apitoken,
                        'apitoken': token,
                        'Content-Type': 'application/json',

                    }
                },
            );

            let mydata = result.data
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            return (resultRegister)

        } catch (error) {
            return (`err in GetListJoin : ${error}`)
        }
    }
    async GetCoinBuy(National) {
        // console.log(National)
        let Market = National == true ? 'zarinpal' : 'WebAppP'
        const user = { data: {} }
        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
        // console.log(data)
        try {

            let result = await axios.post(`https://api.membersgram.com/main/v1/getcoins`,
                {
                    "data": data

                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: Market,

                        'mainapitoken': this.Token,
                        // 'apitoken': token,
                        'Content-Type': 'application/json',

                    }
                },
            );

            let mydata = result.data


            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))


            return (resultRegister)

        } catch (error) {
            return (`err in GetCoinBuy : ${error}`)
        }
    }
    async GetPayment(apitoken, phonenumbers, status, NowPAge) {

        const user = { data: { phonenumbers } }
        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);

        try {

            let result = await axios.post(`https://api.membersgram.com/main/v1/getusertransactions?filter=${status}&page=${NowPAge}`,
                {
                    "data": data

                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',

                        'mainapitoken': apitoken,
                        // 'apitoken': token,
                        'Content-Type': 'application/json',

                    }
                },
            );

            let mydata = result.data


            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))


            return (resultRegister)

        } catch (error) {
            return (`err in GetPayment : ${error}`)
        }
    }
    async GiftApi(apitoken, giftCode) {

        const user = { data: { giftCode } }
        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
        try {

            let result = await axios.post(`https://api.membersgram.com/main/v1/getgift`,
                {
                    "data": data

                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',

                        'mainapitoken': apitoken,
                        // 'apitoken': token,
                        'Content-Type': 'application/json',

                    }
                },
            );

            let mydata = result.data


            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            // console.log(resultRegister)

            return (resultRegister)

        } catch (error) {
            return (`err in GiftApi : ${error}`)
        }
    }
    async JoinChannel(tgChannelId, username, token, apitoken, Id, _id) {

        console.log(tgChannelId, username, token, apitoken, Id, _id)
        const user = { data: { username, tgChannelId, Id, _id } }
        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);

        try {

            let result = await axios.post(`https://api.membersgram.com/main/v2/webapp/joinChannel`,
                {
                    "data": data

                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'mainapitoken': token,
                        'apitoken': apitoken,
                        'Content-Type': 'application/json',

                    }
                },
            );

            let mydata = result.data
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))


            return (resultRegister)

        } catch (error) {
            return (`err in JoinChannel : ${error}`)
        }
    }
    async TransferCoinApi(Token, email, coin) {

        const user = { data: { email, coin } }
        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
        //  console.log(apitoken)
        try {

            let result = await axios.post(`https://api.membersgram.com/main/v1/sendcoin`,
                {
                    "data": data

                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',

                        'mainapitoken': Token,
                        // 'apitoken': token,
                        'Content-Type': 'application/json',

                    }
                },
            );

            let mydata = result.data

            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            // console.log(resultRegister)

            return (resultRegister)

        } catch (error) {
            return (`err in TransferCoinApi : ${error}`)
        }
    }
    async TransferCoinApiHistory(Token) {

        const user = { data: {} }
        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);

        try {

            let result = await axios.post(`https://api.membersgram.com/main/v1/sendcoinhistory`,
                {
                    "data": data

                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',

                        'mainapitoken': Token,
                        // 'apitoken': token,
                        'Content-Type': 'application/json',

                    }
                },
            );

            let mydata = result.data


            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))


            return (resultRegister)

        } catch (error) {
            return (`err in GetCoinBuy : ${error}`)
        }
    }
    async MyOrderApiAll(page, TypeFilter, StatusFilter, username1, phonenumbers) {
        let type = TypeFilter == false || TypeFilter == 'Type' || TypeFilter == null ? '' : TypeFilter == "View" ? "view" : TypeFilter == "Member" ? "member" : null
        let status = StatusFilter == false || StatusFilter == null ? '' : StatusFilter == "Done" ? "done" : StatusFilter == 'Prosessing' ? "inprocess" : StatusFilter == 'Stop' ? "stop" : StatusFilter == 'Report' ? "report" : ''
        let username = username1 == "" || username1 == "null" ? "" : username1
        const user = { data: { phonenumbers } }
        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
        try {
            let result = await axios.post(`https://api.membersgram.com/main/v1/orders?page=${page}&status=${status}&username=${username}&orderType=${type}`,
                {
                    "data": data
                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'mainapitoken': this.Token,
                        'Content-Type': 'application/json'
                    }
                },
            );
            let mydata = result.data
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            return (resultRegister)
        } catch (error) {
            return (`err in MyOrderApiAll : ${error}`)
        }
    }
    async MyOrderApiAllByUsername(page, TypeFilter, StatusFilter, username1, phonenumbers) {
        let type = TypeFilter == false || TypeFilter == 'Type' || TypeFilter == null ? '' : TypeFilter == "View" ? "view" : TypeFilter == "Member" ? "member" : null
        let status = StatusFilter == false || StatusFilter == null ? '' : StatusFilter == "Done" ? "done" : StatusFilter == 'Prosessing' ? "inprocess" : StatusFilter == 'Stop' ? "stop" : StatusFilter == 'Report' ? "report" : ''
        let username = username1 == "" || username1 == "null" ? "" : username1
        const user = { data: { phonenumbers } }
        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
        try {
            let result = await axios.post(`https://api.membersgram.com/main/v1/orders/username?page=${page}&status=${status}&username=${username}&orderType=${type}`,
                {
                    "data": data
                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'mainapitoken': this.Token,
                        'Content-Type': 'application/json',

                    }
                },
            );
            let mydata = result.data
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            return (resultRegister)
        } catch (error) {
            return (`err in MyOrderApiAllByUsername : ${error}`)
        }
    }
    async MyOrderApiById(id) {
        const user = { data: {} }
        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
        try {
            let result = await axios.post(`https://api.membersgram.com/main/v1/orders/${id}`,
                {
                    "data": data
                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'mainapitoken': this.Token,
                        'Content-Type': 'application/json',

                    }
                },
            );
            let mydata = result.data
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            return (resultRegister)
        } catch (error) {
            return (`err in MyOrderApiById : ${error}`)
        }
    }
    async CheckDeleteAccountApi(apitoken) {
        const user = { data: {} }
        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
        try {
            let result = await axios.post(`https://api.membersgram.com/main/v1/checkdelete`,
                {
                    "data": data
                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'mainapitoken': this.Token,
                        'Content-Type': 'application/json',
                    }
                },
            );
            let mydata = result.data
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            return (resultRegister)

        } catch (error) {
            return (`err in CheckDeleteAccountApi : ${error}`)
        }
    }
    async DeleteAccountApi() {
        const user = { data: {} }
        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
        try {
            let result = await axios.post(`https://api.membersgram.com/main/v1/deleteaccount`,
                {
                    "data": data
                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'mainapitoken': this.Token,
                        'Content-Type': 'application/json',
                    }
                },
            );
            let mydata = result.data
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            return (resultRegister)
        } catch (error) {
            return (`err in DeleteAccountApi : ${error}`)
        }
    }
    async TokenRegister(enc) {
        try {
            let result = await axios.post('https://api.membersgram.com/main/v2/verifyRegister', {
                data: enc
            },
                {
                    headers: {
                        versionc: this.VersionCode,
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "*",
                        'Content-Type': 'application/json',
                        "Access-Control-Allow-Headers": "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'",
                        market: 'WebAppP',
                        "mainapitoken": this.Token
                    }
                },)

            let mydata = result.data
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            return resultRegister
        } catch (error) {
            return (`err in TokenRegister : ${error.message}`)
        }
    }
    async FirstRegister(enc) {
        try {
            let result = await axios.post('https://api.membersgram.com/main/v2/register', {
                data: enc
            },
                {
                    headers: {
                        versionc: this.VersionCode,
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "*",
                        'Content-Type': 'application/json',
                        "Access-Control-Allow-Headers": "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'",
                        market: 'WebAppP',
                        "mainapitoken": this.Token
                    }
                },)
            let mydata = result.data
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            return resultRegister
        } catch (error) {
            return (`err in FirstRegister : ${error.message}`)
        }
    }
    async ReportChannelAPI(username, tgChannelId, token) {
        const user = {
            "data": {
                "username": username,
                "tgChannelId": tgChannelId,
            }
        }
        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
        try {
            let result = await axios.post("https://api.membersgram.com/api/v2/reportchannel",
                {
                    "data": data
                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'apitoken': token,
                        'mainapitoken': this.Token,
                        'Content-Type': 'application/json',
                    }
                },
            );
            let mydata = result.data
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            return (resultRegister)
        } catch (error) {
            return (`err in ReportChannelAPI : ${error.message}`)
        }
    }
    async SetPasswordAPI(Password) {
        const user = {
            "data": {
                "password": Password,
            }
        }
        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
        try {
            let result = await axios.post("https://api.membersgram.com/main/v1/getprofile/setpassword",
                {
                    "data": data
                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'apitoken': this.Token,
                        'mainapitoken': this.Token,
                        'Content-Type': 'application/json',
                    }
                },
            );
            let mydata = result.data
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            return (resultRegister)
        } catch (error) {
            return (`err in SetPasswordAPI : ${error.message}`)
        }
    }
    async GetChangePasswordApi(Password, newPassword) {
        const user = {
            "data": {
                "password": Password,
                "newPassword": newPassword,
            }
        }
        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
        try {
            let result = await axios.post("https://api.membersgram.com/main/v1/getprofile/changepassword",
                {
                    "data": data
                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'apitoken': this.Token,
                        'mainapitoken': this.Token,
                        'Content-Type': 'application/json',
                    }
                },
            );
            let mydata = result.data
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            return (resultRegister)
        } catch (error) {
            return (`err in GetChangePasswordApi : ${error.message}`)
        }
    }
    async CheckPasswordAPI(Password) {
        const user = {
            "data": {
                "password": Password,
            }
        }
        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
        try {
            let result = await axios.post("https://api.membersgram.com/main/v1/getprofile/checkpassword",
                {
                    "data": data
                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'apitoken': this.Token,
                        'mainapitoken': this.Token,
                        'Content-Type': 'application/json',
                    }
                },
            );
            let mydata = result.data
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            return (resultRegister)
        } catch (error) {
            return (`err in CheckPasswordAPI : ${error.message}`)
        }
    }
    async accountTransfer(TelegramAccountUpdatelimit) {
        const user = {
            "data": {
                "apitokens": TelegramAccountUpdatelimit,
            }
        }
        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
        try {
            let result = await axios.post("https://api.membersgram.com/main/v1/accountTransfer",
                {
                    "data": data
                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'apitoken': this.Token,
                        'mainapitoken': this.Token,
                        'Content-Type': 'application/json',
                    }
                },
            );
            let mydata = result.data
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            return (resultRegister)
        } catch (error) {
            return (`err in accountTransfer : ${error.message}`)
        }
    }
    async newgetUserJoins(BotSession) {
        const user = { data: {} }

        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
        try {
            let result = await axios.post("https://api.membersgram.com/main/v2/newgetUserJoins",
                {
                    "data": data
                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'apitoken': BotSession,
                        'mainapitoken': this.Token,
                        'Content-Type': 'application/json',
                    }
                },
            );
            let mydata = result.data
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            return (resultRegister)
        } catch (error) {
            return (`err in newgetUserJoins : ${error.message}`)
        }
    }
    async leftChannels(BotSession, listLefti) {
        const user = {
            data: {
                "channels_id": listLefti,
                "channels": "s"
            }
        }

        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
        try {
            let result = await axios.post("https://api.membersgram.com/main/v2/leftChannels",
                {
                    "data": data
                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'apitoken': BotSession,
                        'mainapitoken': this.Token,
                        'Content-Type': 'application/json',
                    }
                },
            );
            let mydata = result.data
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            return (resultRegister)
        } catch (error) {
            return (`err in leftChannels : ${error.message}`)
        }
    }
    async getRunningPostForView(BotSession) {
        const user = {
            data: {

            }
        }

        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
        try {
            let result = await axios.post("https://api.membersgram.com/main/v2/view/getRunningPostForView",
                {
                    "data": data
                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'apitoken': BotSession,
                        'mainapitoken': this.Token,
                        'Content-Type': 'application/json',
                    }
                },
            );
            let mydata = result.data
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            return (resultRegister)
        } catch (error) {
            return (`err in leftChannels : ${error.message}`)
        }
    }

    async saveViewPost(BotSession, post_Id) {
        const user = {
            "data": {
                "post_Id": post_Id,
                "postId": 123
            }
        }

        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
        try {
            let result = await axios.post("https://api.membersgram.com/main/v2/view/saveViewPost",
                {
                    "data": data
                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'apitoken': BotSession,
                        'mainapitoken': this.Token,
                        'Content-Type': 'application/json',
                    }
                },
            );
            let mydata = result.data
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            return (resultRegister)
        } catch (error) {
            return (`err in leftChannels : ${error.message}`)
        }
    }
    async CheckUpdateApi(Version) {
        const user = {
            "data": {
                "version": Version
            }
        }

        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
        try {
            let result = await axios.post("https://api.membersgram.com/main/v1/webdiyalog",
                {
                    "data": data
                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'mainapitoken': this.Token,
                        'Content-Type': 'application/json',
                    }
                },
            );
            let mydata = result.data
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            return (resultRegister)
        } catch (error) {
            return (`err in leftChannels : ${error.message}`)
        }
    }


    async SessionsAccount(Session, token, userv, SaveRes) {
        // console.log(Session)
        // console.log(SaveRes)
        SaveRes = SaveRes == null || SaveRes == '' || !SaveRes ? false : true
        const user = {
            "data": {
                "sessions": [{ 'session': Session, 'apitoken': token, 'user': userv, 'SaveRes': SaveRes }]
            }
        }
        // console.log(user)
        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
        try {
            let result = await axios.post("https://api.membersgram.com/main/v1/sessionsaccount",
                {
                    "data": data
                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'mainapitoken': this.Token,
                        'Content-Type': 'application/json',
                    }
                },
            );
            let mydata = result.data
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            return (resultRegister)
        } catch (error) {
            return (`err in leftChannels : ${error.message}`)
        }
    }
    async SessionsAccountPut(ActiveAccount) {
        // console.log(ActiveAccount)
        const user = {
            "data": {
                "sessions": ActiveAccount
                // "sessions": []
            }
        }

        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
        // console.log(this.Token)
        // console.log(data)
        try {
            //             const res = await axios.put('https://api.membersgram.com/main/v1/sessionsaccount', 'hello=world');
            // console.log(res)
            let result = await axios.put("https://api.membersgram.com/main/v1/sessionsaccount",
                {
                    "data": data
                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'mainapitoken': this.Token,
                        'Content-Type': 'application/json',
                    }
                },
            );
            let mydata = result.data
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            // console.log(resultRegister)
            return (resultRegister)
        } catch (error) {
            return (`err in leftChannels : ${error.message}`)
        }
    }


    async GetSellPhoneNumber(phonenumber) {
        // console.log(ActiveAccount)
        const user = {
            "data": {
                "phonenumber": phonenumber
                // "sessions": []
            }
        }

        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
        // console.log(this.Token)
        // console.log(data)
        try {

            let result = await axios.post("https://api.membersgram.com/main/v1/webapp/getsellphonenumber",
                {
                    "data": data
                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'mainapitoken': this.Token,
                        'Content-Type': 'application/json',
                    }
                },
            );
            let mydata = result.data
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            // console.log(resultRegister)
            return (resultRegister)
        } catch (error) {
            return (`err in leftChannels : ${error.message}`)
        }
    }
    async GetSellCode(phonenumber, code) {
        // console.log(phonenumber)
        // console.log(code)
        const user = {
            "data": {
                "phonenumber": phonenumber,
                "code": code
            }
        }

        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
        // console.log(this.Token)
        // console.log(data)
        try {

            let result = await axios.post("https://api.membersgram.com/main/v1/webapp/getsellcode",
                {
                    "data": data
                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'mainapitoken': this.Token,
                        'Content-Type': 'application/json',
                    }
                },
            );
            let mydata = result.data
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            // console.log(resultRegister)
            return (resultRegister)
        } catch (error) {
            return (`err in leftChannels : ${error.message}`)
        }
    }
    async AccountConfirmation() {
        // console.log(phonenumber)
        // console.log(code)
        const user = {
            "data": {
                // "phonenumber": phonenumber,
                // "code":code
            }
        }

        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
        // console.log(this.Token)
        // console.log(data)
        try {

            let result = await axios.post("https://api.membersgram.com/main/v1/webapp/accountconfirmation",
                {
                    "data": data
                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'mainapitoken': this.Token,
                        'Content-Type': 'application/json',
                    }
                },
            );
            let mydata = result.data
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            // console.log(resultRegister)
            return (resultRegister)
        } catch (error) {
            return (`err in leftChannels : ${error.message}`)
        }
    }
    async CheckLogout(id, phonenumber) {
        // console.log(phonenumber)
        // console.log(code)
        const user = {
            "data": {
                "phonenumber": phonenumber,
                "_id": id
            }
        }

        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
        // console.log(this.Token)
        // console.log(data)
        try {

            let result = await axios.post("https://api.membersgram.com/main/v1/webapp/checklogout",
                {
                    "data": data
                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'mainapitoken': this.Token,
                        'Content-Type': 'application/json',
                    }
                },
            );
            let mydata = result.data
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            // console.log(resultRegister)
            return (resultRegister)
        } catch (error) {
            return (`err in leftChannels : ${error.message}`)
        }
    }

    async GetHistoryFunc(page) {
        // console.log(ActiveAccount)
        const user = {
            "data": {
                page
            }
        }

        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
        // console.log(this.Token)
        // console.log(data)
        try {

            let result = await axios.post("https://api.membersgram.com/main/v1/webapp/accounthistory",
                {
                    "data": data
                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'mainapitoken': this.Token,
                        'Content-Type': 'application/json',
                    }
                },
            );
            let mydata = result.data
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            // console.log(resultRegister)
            return (resultRegister)
        } catch (error) {
            return (`err in leftChannels : ${error.message}`)
        }
    }

    async GetTouchBack() {
        // console.log(ActiveAccount)
        const user = {
            "data": {

            }
        }

        let data = cryptlib.encrypt(JSON.stringify(user), this.key, this.iv);
        // console.log(this.Token)
        // console.log(data)
        try {

            let result = await axios.post("https://api.membersgram.com/main/v1/webapp/touchback",
                {
                    "data": data
                },
                {
                    headers: {
                        lan: '',
                        versionc: this.VersionCode,
                        market: 'WebAppP',
                        'mainapitoken': this.Token,
                        'Content-Type': 'application/json',
                    }
                },
            );
            let mydata = result.data
            let resultRegister = JSON.parse(cryptlib.decrypt(mydata, this.key, this.iv))
            // console.log(resultRegister)
            return (resultRegister)
        } catch (error) {
            return (`err in leftChannels : ${error.message}`)
        }
    }



}
