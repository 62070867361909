import React from 'react'
import { AllApi } from '../Api/AllApi'
import { GameDB } from './../DataBase/GameDB'

export class OnlineRequestAppSettingGame extends React.Component {
    constructor() {
        super();
    }
    async GetAppSetting () {
        let Data;
        const MakeApiClass = new AllApi();
        let Result = await MakeApiClass.appSetting()
        // console.log(Result)
        if (Result.code == 200) {
            Data = Result
        } else if (Result.code == 999) {
            Data = 'Network'
        }
        return Data
    }
   async OnlineRequestAppSetting() {
    let AppSettingRes = await this.GetAppSetting()
    if (AppSettingRes != 'Network') {
        let CashTime = AppSettingRes.data.cashTime
        let NowInNow = new Date().getTime();
        let LastTime = CashTime * 1000 + NowInNow;
        GameDB.AppSetting.toArray(function (AppSettingItem) {
            if (AppSettingItem.length > 0) {
                GameDB.AppSetting.toCollection().modify(App => {
                    App.LastTime = LastTime;
                    // App.LastUpdate = NowInNow
                    App.AppSetting = AppSettingRes.data.appSetting
                });
            } else {
                GameDB.AppSetting.add({ AppSetting: AppSettingRes.data.appSetting, LastTime, LastUpdate: NowInNow });
            }

        });
    } else {
        // handleClick({ vertical: 'bottom', horizontal: 'center' })
        // OfflineRequestAppSetting()
    }
    return AppSettingRes
    }


}
