import React from 'react'
import './TopJoiner.css'

import { useState, useRef } from 'react';

import User1 from './../../Public/Image/Game/topjoiner/1.svg'
import User2 from './../../Public/Image/Game/topjoiner/2.svg'
import User3 from './../../Public/Image/Game/topjoiner/3.svg'
// import Img from './../../Public/Image/Game/topjoiner/img.png'
import Gift from './../../Public/Image/Game/topjoiner/gift.svg'
import Vector from './../../Public/Image/Game/topjoiner/Vector.svg'
import Coin from './../../Public/Image/Game/Coin.svg'
import Star from './../../Public/Image/Game/Star.svg'
import Congratulations from './../BottomSheets/Congratulations'
import Congratulations2 from './../BottomSheets/Congratulations2'
import Congratulations3 from './../BottomSheets/Congratulations3'
import Promotion from './../BottomSheets/Promotion'
import './../GameOver.css'

import { SetCongratulationsBottomSheet } from './../../app/Game'
import { SetCongratulationsBottomSheet2 } from './../../app/Game'
import { SetCongratulationsBottomSheet3 } from './../../app/Game'
import { SetPromotionBottomSheet } from './../../app/Game'
import { SetLeagueEnd } from './../../app/Game'

import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { AllApi } from '../../Api/AllApi'
import Lottie from "lottie-react";
import Animation from './../../Public/_013_CONTEST.json'
// import Ellipse1 from './../../Public/Image/Game/topjoiner/Ellipse1.svg'
import moment from 'jalali-moment'
import Loading from './../../Loading'
import { Link } from 'react-router-dom';
import Img from './../../Public/Image/Game/over/1.svg'
// import LottiePlayer from "@lottiefiles/lottie-player";
// import * as LottiePlayer from "@lottiefiles/lottie-player";
// import { DotLottieReact } from '@lottiefiles/dotlottie-react';
// import { Crisp } from "crisp-sdk-web";
// import Sticker1 from './../../Public/Animation/AnimatedSticker.tgs'
// import Sticker from './../../Public/Animation/ezgif-6-db126db08f.gif'
// import Crisp from "crisp-react";
import { GameDB } from './../../DataBase/GameDB'
// import { SetChangeLeagueAndReqApi } from './../../app/Game'


const TopJoinerComponent = ({ IsBetweenLeaguge, Time }) => {
  const GiftTopjoin = useSelector((state) => state.Game.GiftTopjoin)
  const UserLeagueData = useSelector((state) => state.Game.UserLeagueData)
  const AppSettingData = useSelector((state) => state.Game.AppSettingData)
  const [TopjoinerState, setTopjoinerState] = useState(false)
  const [MyUser, setMyUser] = useState(false)
  const [IsInTop100, setIsInTop100] = useState(0)
  const TopJoinerTime = useSelector((state) => state.Game.TopJoinerTime)
  const JoinCountRedux = useSelector((state) => state.Game.JoinCountRedux)

  const [LoadingState, setLoadingState] = useState(true)
  const dispatch = useDispatch()
  const LeagueEnd = useSelector((state) => state.Game.LeagueEnd)


  const Style = {

    Grandmaster: {
      // img: GrandMasterImg,
      Rank: '#1C1C86',
      Progress: '#0202D3',
      ListBorder: '#2B389F',
      ListTitleBg: '#2B389F',
      ListTitleText: '#FFFFFF',
      ListTitleYou: '#0202A9',
      ListTitleYouBg: '#fff',
      ListBodyBg: '#FFFFFF',
      ListBodyText: '#212121'
    },

  }


  const OfflineRequestTopJoiner = async () => {
    setLoadingState(true)
    // dispatch(SetCongratulationsBottomSheet(true))
    // console.log('OfflineRequestTopJoiner')
    let TopJoiner = await GameDB.TopJoiner.toArray()
    console.log(TopJoiner)
    setTopjoinerState(TopJoiner[0].TopJoiner)
    setMyUser(TopJoiner[0].userInformation)

    TopJoiner[0].TopJoiner.map((item, i) => {
      // console.log(item)
      // console.log(IsBetweenLeaguge)
      // console.log(TopJoiner[0].userInformation.email)
      // console.log(item.email)
      if (item.email == TopJoiner[0].userInformation.email) {
        console.log(i + 1)
        console.log(IsBetweenLeaguge)
        setIsInTop100(i + 1)
        // console.log(0)
        let ShowPrevBottomSheet = localStorage.getItem('CloseCongratulations')
        let curTime = Date.now();
        console.log(ShowPrevBottomSheet)
        if (curTime + 3600000 < ShowPrevBottomSheet || ShowPrevBottomSheet == null) {
          if (IsBetweenLeaguge) {
            // console.log(1)
            if ((i + 1) == 1) {
              // console.log(i + 1)/
              setTimeout(() => {
                dispatch(SetCongratulationsBottomSheet(true))
              }, 1000);

            } else if ((i + 1) == 2) {
              setTimeout(() => {
                dispatch(SetCongratulationsBottomSheet2(true))
              }, 1000);


            } else if ((i + 1) == 3) {
              setTimeout(() => {
                dispatch(SetCongratulationsBottomSheet3(true))
              }, 1000);


            }
          }
        }
      }
      // else{
      //   setIsInTop100(i+1)

      // }
    })
    setLoadingState(false)

  }
  useEffect(() => {
    // console.log(LeagueEnd)
    // if (LeagueEnd) {
    // console.log(IsBetweenLeaguge)

    OfflineRequestTopJoiner()
    // setMyLeague(false)
    // }
  }, [IsBetweenLeaguge,UserLeagueData])

  // const CheckBetweenTimeFromAppSetting = async () => {
  //   let curTime = Date.now();
  //   let AppSetting = await GameDB.AppSetting.toArray()
  //   // let TestBetween=curTime + 50000
  //   // console.log(TestBetween)
  //   let betweenTime = AppSetting[0].AppSetting.timebetweenLeague - curTime
  //   // let betweenTime = 1726302784905 - curTime


  //   if (betweenTime) {
  //     const betweenTimeseconds = Math.floor(betweenTime / 1000);
  //     const betweenTimeminutes = Math.floor(betweenTimeseconds / 60);
  //     const betweenTimehours = Math.floor(betweenTimeminutes / 60);
  //     const betweenTimedays = Math.floor(betweenTimehours / 24);
  //     const remainingSeconds = betweenTimeseconds % 60;
  //     const remainingMinutes = betweenTimeminutes % 60;
  //     const remainingHours = betweenTimehours % 24;
  //     let m, h, s, day;
  //     m = String(remainingMinutes).padStart(2, "0");
  //     h = String(remainingHours).padStart(2, "0");
  //     s = String(remainingSeconds).padStart(2, "0");
  //     day = String(betweenTimedays).padStart(2, "0");
  //     // console.log(day, h, m, s)
  //     return { day, h, m, s }
  //   }
  // }
  // const CheckAppSettingTimes = async () => {
  //   let curTime = Date.now();
  //   // timebetweenLeague
  //   // setLoadingState(true)

  //   let AppSetting = await GameDB.AppSetting.toArray()
  //   // let AppSetting1 = await OnlineRequestTopJoiner()
  //   // console.log(AppSetting1)

  //   let CheckBetweenTimeResponse = await CheckBetweenTimeFromAppSetting()
  //   // console.log(CheckBetweenTimeResponse)
  //   let Day_To_Number = Number(CheckBetweenTimeResponse.day)
  //   let Hour_To_Number = Number(CheckBetweenTimeResponse.h)
  //   let Minute_To_Number = Number(CheckBetweenTimeResponse.m)
  //   let Second_To_Number = Number(CheckBetweenTimeResponse.s)
  //   // console.log(Day_To_Number)
  //   if (Day_To_Number <= 0 && Hour_To_Number <= 0 && Minute_To_Number <= 0 && Second_To_Number <= 0) {
  //     // League in Runing
  //     dispatch(SetChangeLeagueAndReqApi(true))
  //     // if (IsBetweenLeaguge == true) {
  //     // console.log('BetweenLeaguge false')
  //     // setIsBetweenLeaguge(false)
  //     // }



  //     let finaltime = AppSetting[0].AppSetting.timeFinishLeague - curTime


  //     const seconds = Math.floor(finaltime / 1000);
  //     const minutes = Math.floor(seconds / 60);
  //     const hours = Math.floor(minutes / 60);
  //     const days = Math.floor(hours / 24);
  //     const remainingSeconds = seconds % 60;
  //     const remainingMinutes = minutes % 60;
  //     const remainingHours = hours % 24;
  //     let m, h, s, day;
  //     m = String(remainingMinutes).padStart(2, "0");
  //     h = String(remainingHours).padStart(2, "0");
  //     s = String(remainingSeconds).padStart(2, "0");
  //     day = String(days).padStart(2, "0");

  //     // setTime({
  //     //   days: day,
  //     //   hours: h,
  //     //   minutes: m,
  //     //   seconds: s,
  //     // })
  //     dispatch(SetLeagueEnd(false))

  //   } else {
  //     // League in Ended
  //     dispatch(SetChangeLeagueAndReqApi(false))
  //     dispatch(SetLeagueEnd(true))

  //     // if (IsBetweenLeaguge == false) {
  //     console.log('between leage is true')
  //     // setIsBetweenLeaguge(true)
  //     // }


  //     // let TestBetween=curTime + 50000
  //     // console.log(TestBetween)
  //     let finaltime = AppSetting[0].AppSetting.timebetweenLeague - curTime
  //     // let finaltime = 1726302784905 - curTime


  //     const seconds = Math.floor(finaltime / 1000);
  //     const minutes = Math.floor(seconds / 60);
  //     const hours = Math.floor(minutes / 60);
  //     const days = Math.floor(hours / 24);

  //     const remainingSeconds = seconds % 60;
  //     const remainingMinutes = minutes % 60;
  //     const remainingHours = hours % 24;
  //     let m, h, s, day;
  //     m = String(remainingMinutes).padStart(2, "0");
  //     h = String(remainingHours).padStart(2, "0");
  //     s = String(remainingSeconds).padStart(2, "0");
  //     day = String(days).padStart(2, "0");

  //     // setTime({
  //     //   days: day,
  //     //   hours: h,
  //     //   minutes: m,
  //     //   seconds: s,
  //     // })


  //   }
  // }
  // const [runInterval, setRunInterval] = useState(true);

  // const intervalRef = useRef(null);
  // useEffect(() => {

  //   if (runInterval) {
  //     intervalRef.current = setInterval(() => {
  //       CheckAppSettingTimes()
  //     }, 1000);
  //   }

  //   return () => {
  //     clearInterval(intervalRef.current);
  //   };
  // }, [runInterval]);

  // useEffect(() => {
  //   OfflineRequestTopJoiner()
  // }, [IsBetweenLeaguge])
  // let refreshIntervalId
  // useEffect(() => {
  //   // GetAllListJoinsfunc()
  //   // Crisp.configure("e77e756c-210d-41c8-b3a6-1271153c5f7a");


  //   // refreshIntervalId = setInterval(millisecondsToTime, 1000);

  //   OfflineRequestTopJoiner()
  //   // BetWeenFunction()
  // }, [])
  // console.log(Time)
  return (

    LoadingState == false ?





      IsBetweenLeaguge ?
        <Loading />
        // <div className='GameOverContent'>
        //   <Congratulations />
        //   <Congratulations2 />
        //   <Congratulations3 />
        //   <Promotion />
        //   <div>
        //     {/* <img src={Img} /> */}
        //     <Lottie animationData={Animation} loop={true} style={{ "width": "120px", "height": "120px" }} />
        //     {/* <img src={Sticker} style={{ "width": "120px", "height": "120px" }} /> */}
        //   </div>
        //   <div className='GameOverContentTitle'>Competition is over</div>

        //   <div className='TopJoinerDetails'>

        //     <div className='TopJoinerDetailItem'>
        //       <div className='TopJoinerDetailImage'>
        //         <img src={User2} className='UserImageTopJoinerDetailImage2' />
        //         {TopjoinerState[1].picture ?
        //           <img className='TopJoinerDetailImageUserInnerImage2'
        //             src={TopjoinerState[1].picture} />
        //           :
        //           <div className='ShowFirstLetter'>
        //             {TopjoinerState[1].nameAccount.substring(0, 1).toUpperCase()}
        //           </div>}
        //       </div>
        //       <div className='TopJoinerDetailName'>
        //         {TopjoinerState[1].nameAccount.substring(0, 8)}
        //       </div>
        //       <div className='TopJoinerDetailJoins'>
        //         {/* 7,985,200 Join */}
        //         {TopjoinerState[1].joinCount.toLocaleString()} <b>Join</b>
        //       </div>
        //       <div className='TopJoinerDetailGift'>
        //         <div className='TopJoinerDetailGiftImg'>
        //           <img src={Gift} width={18} height={18} />
        //         </div>
        //         <div className='TopJoinerDetailGiftInner'>
        //           <div className='TopJoinerDetailGiftContImg'>
        //             <img src={Vector} width={8} height={60} />
        //           </div>
        //           <div className='TopJoinerDetailGiftInnerItems'>
        //             <div className='TopJoinerDetailGiftInnerItemsCoin'>{GiftTopjoin ? GiftTopjoin[1].coin : ''} <img className='TopJoinerDetailGiftInnerItemsCoinImg' src={Coin} /></div>
        //             <div className='TopJoinerDetailGiftInnerItemsxp'>{GiftTopjoin ? GiftTopjoin[1].xp : ''} <img className='TopJoinerDetailGiftInnerItemsxpImg' src={Star} /></div>
        //           </div>

        //         </div>

        //       </div>
        //     </div>

        //     <div className='TopJoinerDetailItem CenterTopJoinerDetailItem'>
        //       <div className='TopJoinerDetailImage'>
        //         <img src={User1} className='UserImageTopJoinerDetailImage' />
        //         {
        //           TopjoinerState[0].picture ?
        //             <img className='TopJoinerDetailImageUserInnerImage'
        //               src={TopjoinerState[0].picture}
        //               style={{ 'backgroundImage': `url(${TopjoinerState[0].picture})` }}
        //             /> :
        //             <div className='ShowFirstLetter1'>
        //               {TopjoinerState[0].nameAccount.substring(0, 1).toUpperCase()}
        //             </div>
        //         }

        //       </div>
        //       <div className='TopJoinerDetailName'>
        //         {TopjoinerState[0].nameAccount.substring(0, 8)}

        //       </div>
        //       <div className='TopJoinerDetailJoins'>
        //         {TopjoinerState[0].joinCount.toLocaleString()} <b>Join</b>
        //       </div>
        //       <div className='TopJoinerDetailGift'>
        //         <div className='TopJoinerDetailGiftImg'>
        //           <img src={Gift} width={18} height={18} />
        //         </div>
        //         <div className='TopJoinerDetailGiftInner'>
        //           <div className='TopJoinerDetailGiftContImg'>
        //             <img src={Vector} width={8} height={60} />
        //           </div>
        //           <div className='TopJoinerDetailGiftInnerItems'>
        //             <div className='TopJoinerDetailGiftInnerItemsCoin'>{GiftTopjoin ? GiftTopjoin[0].coin : ''} <img className='TopJoinerDetailGiftInnerItemsCoinImg' src={Coin} /></div>
        //             <div className='TopJoinerDetailGiftInnerItemsxp'>{GiftTopjoin ? GiftTopjoin[0].xp : ''} <img className='TopJoinerDetailGiftInnerItemsxpImg' src={Star} /></div>
        //           </div>

        //         </div>

        //       </div>
        //     </div>

        //     <div className='TopJoinerDetailItem'>
        //       <div className='TopJoinerDetailImage'>
        //         <img src={User3} className='UserImageTopJoinerDetailImage2' />
        //         {
        //           TopjoinerState[2].picture ?
        //             <img className='TopJoinerDetailImageUserInnerImage2'
        //               src={TopjoinerState[2].picture} />
        //             :
        //             <div className='ShowFirstLetter3'>
        //               {TopjoinerState[2].nameAccount.substring(0, 1).toUpperCase()}
        //             </div>
        //         }
        //       </div>
        //       <div className='TopJoinerDetailName'>

        //         {TopjoinerState[2].nameAccount.substring(0, 8)}
        //       </div>
        //       <div className='TopJoinerDetailJoins'>

        //         {TopjoinerState[2].joinCount.toLocaleString()} <b>Join</b>
        //       </div>
        //       <div className='TopJoinerDetailGift'>
        //         <div className='TopJoinerDetailGiftImg'>
        //           <img src={Gift} width={18} height={18} />
        //         </div>
        //         <div className='TopJoinerDetailGiftInner'>
        //           <div className='TopJoinerDetailGiftContImg'>
        //             <img src={Vector} width={8} height={60} />
        //           </div>
        //           <div className='TopJoinerDetailGiftInnerItems'>
        //             <div className='TopJoinerDetailGiftInnerItemsCoin'>{GiftTopjoin ? GiftTopjoin[2].coin : ''}  <img className='TopJoinerDetailGiftInnerItemsCoinImg' src={Coin} /></div>
        //             <div className='TopJoinerDetailGiftInnerItemsxp'>{GiftTopjoin ? GiftTopjoin[2].xp : ''} <img className='TopJoinerDetailGiftInnerItemsxpImg' src={Star} /></div>
        //           </div>

        //         </div>

        //       </div>
        //     </div>

        //   </div>
        //   <div className='GameOverContentFooter'>
        //     Time to start the next competition:
        //   </div>
        //   <div>
        //     <div className='TopJoinerTimes'>


        //       <div className='TopJoinerTimesItem'>
        //         <div className='TopJoinerTimesNumber'>
        //           {Time.hours}
        //           {/* 58 */}
        //         </div>
        //         <div className='TopJoinerTimesString'>Hour</div>
        //       </div>
        //       <span className='Wdot'>:</span>
        //       <div className='TopJoinerTimesItem'>
        //         <div className='TopJoinerTimesNumber'>
        //           {Time.minutes}
        //           {/* 25 */}
        //         </div>
        //         <div className='TopJoinerTimesString'>Min</div>
        //       </div>
        //       <span className='Wdot'>:</span>

        //       <div className='TopJoinerTimesItem'>
        //         <div className='TopJoinerTimesNumber'>
        //           {Time.seconds}
        //           {/* 25 */}
        //         </div>
        //         <div className='TopJoinerTimesString'>Sec</div>
        //       </div>

        //     </div>
        //   </div>
        // </div>
        :
        <div className='TopJoinerContainer'>
          {/* <Congratulations />
          <Congratulations2 />
          <Congratulations3 /> */}
          {/* <Promotion /> */}
          <div className='TopJoinerTimeToEnd'>
            <div className='TopJoinerTimeToEndTitle'>Time to End:</div>


            <div className='TopJoinerTimes'>
              <div className='TopJoinerTimesItem'>
                <div className='TopJoinerTimesNumber'>{Time.days}</div>
                <div className='TopJoinerTimesString'>Day</div>
              </div>
              <span className='Wdot'>:</span>
              <div className='TopJoinerTimesItem'>
                <div className='TopJoinerTimesNumber'>{Time.hours}</div>
                <div className='TopJoinerTimesString'>Hour</div>
              </div>
              <span className='Wdot'>:</span>
              <div className='TopJoinerTimesItem'>
                <div className='TopJoinerTimesNumber'>{Time.minutes}</div>
                <div className='TopJoinerTimesString'>Min</div>
              </div>
              <span className='Wdot'>:</span>
              <div className='TopJoinerTimesItem'>
                <div className='TopJoinerTimesNumber'>{Time.seconds}</div>
                <div className='TopJoinerTimesString'>Sec</div>
              </div>
            </div>

          </div>

          {TopjoinerState[1] ?
            <div className='TopJoinerImagesContainer'>
              <div className='TopJoinerDetails'>

                <div className='TopJoinerDetailItem'>
                  <div className='TopJoinerDetailImage'>
                    <img src={User2 ? User2 : ''} className='UserImageTopJoinerDetailImage2' />
                    {

                      TopjoinerState[1].picture ?
                        <img className='TopJoinerDetailImageUserInnerImage2'
                          src={TopjoinerState[1].picture} />
                        :
                        <div className='ShowFirstLetter'>
                          {TopjoinerState[1].nameAccount.substring(0, 1).toUpperCase()}
                        </div>

                    }
                  </div>
                  <div className='TopJoinerDetailName'>
                    {TopjoinerState[1].nameAccount.substring(0, 9)}
                  </div>
                  <div className='TopJoinerDetailJoins'>

                    {TopjoinerState[1].joinCount.toLocaleString()} <b>Join</b>
                  </div>
                  <div className='TopJoinerDetailGift'>
                    <div className='TopJoinerDetailGiftImg'>
                      <img src={Gift} width={18} height={18} />
                    </div>
                    <div className='TopJoinerDetailGiftInner'>
                      <div className='TopJoinerDetailGiftContImg'>
                        <img src={Vector} width={8} height={60} />
                      </div>
                      <div className='TopJoinerDetailGiftInnerItems'>
                        <div className='TopJoinerDetailGiftInnerItemsCoin'>{GiftTopjoin ? GiftTopjoin[1].coin : ''} <img className='TopJoinerDetailGiftInnerItemsCoinImg' src={Coin} /></div>
                        <div className='TopJoinerDetailGiftInnerItemsxp'>{GiftTopjoin ? GiftTopjoin[1].xp : ''} <img className='TopJoinerDetailGiftInnerItemsxpImg' src={Star} /></div>
                      </div>

                    </div>

                  </div>
                </div>

                <div className='TopJoinerDetailItem CenterTopJoinerDetailItem'>
                  <div className='TopJoinerDetailImage'>
                    <img src={User1} className='UserImageTopJoinerDetailImage' />
                    {
                      TopjoinerState[0].picture ?
                        <img className='TopJoinerDetailImageUserInnerImage'
                          src={TopjoinerState[0].picture}
                          style={{ 'backgroundImage': `url(${TopjoinerState[0].picture})` }}
                        /> :
                        <div className='ShowFirstLetter1'>
                          {TopjoinerState[0].nameAccount.substring(0, 1).toUpperCase()}
                        </div>
                    }

                  </div>
                  <div className='TopJoinerDetailName'>
                    {TopjoinerState[0].nameAccount.substring(0, 9)}

                  </div>
                  <div className='TopJoinerDetailJoins'>
                    {TopjoinerState[0].joinCount.toLocaleString()} <b>Join</b>
                  </div>
                  <div className='TopJoinerDetailGift'>
                    <div className='TopJoinerDetailGiftImg'>
                      <img src={Gift} width={18} height={18} />
                    </div>
                    <div className='TopJoinerDetailGiftInner'>
                      <div className='TopJoinerDetailGiftContImg'>
                        <img src={Vector} width={8} height={60} />
                      </div>
                      <div className='TopJoinerDetailGiftInnerItems'>
                        <div className='TopJoinerDetailGiftInnerItemsCoin'>{GiftTopjoin ? GiftTopjoin[0].coin : ''} <img className='TopJoinerDetailGiftInnerItemsCoinImg' src={Coin} /></div>
                        <div className='TopJoinerDetailGiftInnerItemsxp'>{GiftTopjoin ? GiftTopjoin[0].xp : ''} <img className='TopJoinerDetailGiftInnerItemsxpImg' src={Star} /></div>
                      </div>

                    </div>

                  </div>
                </div>

                <div className='TopJoinerDetailItem'>
                  <div className='TopJoinerDetailImage'>
                    <img src={User3} className='UserImageTopJoinerDetailImage2' />
                    {
                      TopjoinerState[2].picture ?
                        <img className='TopJoinerDetailImageUserInnerImage2'
                          src={TopjoinerState[2].picture} />
                        :
                        <div className='ShowFirstLetter3'>
                          {TopjoinerState[2].nameAccount.substring(0, 1).toUpperCase()}
                        </div>
                    }
                  </div>
                  <div className='TopJoinerDetailName'>

                    {TopjoinerState[2].nameAccount.substring(0, 8)}
                  </div>
                  <div className='TopJoinerDetailJoins'>

                    {TopjoinerState[2].joinCount.toLocaleString()} <b>Join</b>
                  </div>
                  <div className='TopJoinerDetailGift'>
                    <div className='TopJoinerDetailGiftImg'>
                      <img src={Gift} width={18} height={18} />
                    </div>
                    <div className='TopJoinerDetailGiftInner'>
                      <div className='TopJoinerDetailGiftContImg'>
                        <img src={Vector} width={8} height={60} />
                      </div>
                      <div className='TopJoinerDetailGiftInnerItems'>
                        <div className='TopJoinerDetailGiftInnerItemsCoin'>{GiftTopjoin ? GiftTopjoin[2].coin : ''}  <img className='TopJoinerDetailGiftInnerItemsCoinImg' src={Coin} /></div>
                        <div className='TopJoinerDetailGiftInnerItemsxp'>{GiftTopjoin ? GiftTopjoin[2].xp : ''} <img className='TopJoinerDetailGiftInnerItemsxpImg' src={Star} /></div>
                      </div>

                    </div>

                  </div>
                </div>

              </div>
              <div className='MyLeagueRow4' style={{ borderColor: Style.Grandmaster.ListBorder, borderRadius: '11px' }}>
                <div className='MyLeagueRow4Title' style={{ backgroundColor: Style.Grandmaster.ListTitleBg, borderTopLeftRadius: '4px', borderTopRightRadius: '4px', }}>
                  <div className='MyLeagueRow4TitleRank'>
                    <div className='MyLeagueRow4TitleRankNumber' style={{ color: Style.Grandmaster.ListTitleText }}>

                      {IsInTop100 == 0 ? '+ 100' : '#' + IsInTop100}
                      {/* {TopjoinerState.userInformation.leagueLevel} */}
                    </div>
                    <div className='MyLeagueRow4TitleRankUser' style={{ color: Style.Grandmaster.ListTitleText }}>
                      {MyUser.nameAccount}

                    </div>
                    <div className='MyLeagueRow4TitleRankIsUser' style={{ color: '#2B389F', backgroundColor: Style.Grandmaster.ListTitleYouBg }}>
                      You
                    </div>
                  </div>
                  <div className='MyLeagueRow4TitleJoinRank' style={{ color: Style.Grandmaster.ListTitleText }}>
                    {MyUser.joinCount.toLocaleString()} Join
                  </div>
                </div>
                <div className='MyLeagueRow4Items' style={{ backgroundColor: Style.Grandmaster.ListBodyBg, color: Style.Grandmaster.ListBodyText, border: '2px solid red', borderTop: 'none', width: 'calc(100% - 5px)', marginBottom: '1px', borderRadius: '11px', borderTopLeftRadius: '0', borderTopRightRadius: '0' }}>
                  {
                    TopjoinerState ?
                      TopjoinerState.map((item, i) =>
                        <div className='MyLeagueRow4Item' key={i}>
                          <div className='MyLeagueRow4ItemInner' style={{ color: Style.Grandmaster.ListBodyText }}>
                            <span className='MyLeagueRow4ItemInnerNumber' style={i == 0 ? { color: '#ECC700' } : i == 1 ? { color: '#C0C0C0' } : i == 2 ? { color: '#CD7F32' } : { color: '#212121', fontWeight: '700', fontSize: '14px' }}>{i + 1}</span>

                            <div className='MyLeagueRow4ItemCoulmn'>
                              <div className='MyLeagueRow4ItemCoulmn1'> {item.nameAccount.substring(0, 8)} {MyUser.email == item.email ? <span className='YouBadge'>You</span> : null}</div>
                              <div className='MyLeagueRow4ItemCoulmn2'>{item.leagueName}</div>
                            </div>
                          </div>
                          <div className='MyLeagueRow4ItemInner' style={{ color: Style.Grandmaster.ListBodyText }}>
                            {item.joinCount.toLocaleString()} Join

                          </div>

                        </div>
                      )

                      : null
                  }



                </div>
              </div>
            </div>
            : null}

        </div>


      : <Loading />
  )
}

export default TopJoinerComponent