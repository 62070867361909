import React from 'react'
import { AllApi } from '../Api/AllApi'
import { GameDB } from './../DataBase/GameDB'
import {
    SetAppSettingData,
    SetUserLeagueData,
    SetMyLeagueRank,
    SetLeagueLimitJoinProgress,
    SetLeagueMinimumJoinProgress,
    SetRewardLeague,
    SetMyLeagueRankNumber,
    SetGiftTopjoin,
    SetNameAccount,
    SetJoinCountRedux,
    SetPromotionBottomSheet,
    SetUserReward,
    SetClaimRewardCall,
    SetDataClaimReward,
    SetXpLimit,
    SetXpCount,
    SetLevelUserHeaderProgress,
    SetSnackBarEndLeagueState
} from './../app/Game'
export class OnlineRequestLeagueListGame extends React.Component {
    constructor() {
        super();
    }
    async GetleagueListJoinsfunc() {
        let Data;
        const MakeApiClass = new AllApi();
        let Result = await MakeApiClass.GetleagueListJoins()
        console.log(Result)

        if (Result.code == 200) {
            Data = Result

        } else if (Result.code == 301) {
            Data = 'Name'

        } else if (Result.code == 999) {
            Data = 'Network'
        }
        return Data
    }
    async OnlineRequestLeagueList(dispatch) {

        let GetleagueListJoinsRes = await this.GetleagueListJoinsfunc()
        console.log(GetleagueListJoinsRes)
        if (GetleagueListJoinsRes != 'Network') {
            let LevelUser = false
            LevelUser = GetleagueListJoinsRes.data.data.userInformation.leagueLevel
        
            // dispatch(SetJoinCountRedux(GetleagueListJoinsRes.data.user.joinCount))
            
            let CashTime1 = GetleagueListJoinsRes.data.data.cashTime

            let NowInNow = new Date().getTime();
            let LastTime = CashTime1 * 1000 + NowInNow;

            localStorage.setItem('LastTimeLeague', LastTime)


            GameDB.LeagueList.toArray(function (LeagueListItem) {

                if (LeagueListItem.length > 0) {
                    GameDB.LeagueList.toCollection().modify(LeagueListItem => {

                        LeagueListItem.LastTime = LastTime;
                        LeagueListItem.LeagueList = GetleagueListJoinsRes.data;
                    });
                } else {
                    GameDB.LeagueList.add({ LeagueList: GetleagueListJoinsRes.data, LastTime: LastTime, LastUpdate: NowInNow });
                }

            });
        } else {
            // console.log(GetleagueListJoinsRes)
            // console.log('OfflineRequestLeagueList')
            // handleClick({ vertical: 'bottom', horizontal: 'center' })
            // OfflineRequestLeagueList()
            // console.log('Network')

        }
        return GetleagueListJoinsRes
    }


}
