import React from 'react'
import { useEffect, useState } from 'react'
import { StringSession } from 'telegram/sessions'
import { TelegramClient, Api } from 'telegram'
import { API_ID, API_HASH } from './../Const'
import { TelegramDB } from './../DataBase/TelegramDB'

export async function CalcLeft(item, data) {

    const stringSession = new StringSession(item.Session);
    const Client = new TelegramClient(stringSession, Number(API_ID), API_HASH, {
        connectionRetries: 1,
    });
    const connectPromise = await Client.connect();
    const dialogs = await Client.getDialogs(); 
    // console.log(dialogs)
    let DataFromApi = []
    // data.map(item => {
    //     DataFromApi.push(item.username.toLowerCase())
    // })
    data.map(item => {
        DataFromApi.push(item.tgChannelId)
    })

    let DataFromTelegram = []
    let ChannelIDFromTelegram = []
    // console.log(dialogs)
    // let LeftiArr = []
    dialogs.forEach(
        dialog => {
            if (!dialog.isUser) {
                // console.log(dialog)
                // if (dialog.entity.username != null) {
                    // DataFromTelegram.push(dialog.entity.username.toLowerCase())
                    ChannelIDFromTelegram.push(dialog.entity.id)
                    // ChannelIDFromTelegram
                    // console.log(typeof(dialog.entity.id.value))
                    var ret = dialog.entity.id.value.toString().replace('n', '');
                    // console.log(Number(ret))
                    DataFromTelegram.push(Number(ret))
                   

                // }

            }
        }
    )
    // console.log(DataFromApi) 
  
    // ChannelIDFromTelegram.map(IdItem=>{
    //     console.log(IdItem)
    // })

    let LeftiArr = []
    DataFromApi.map(item1 => {
        let res = DataFromTelegram.indexOf(item1)
        if (res == -1) {
            LeftiArr.push(item1)
        }

    })

    // console.log(LeftiArr)
    return LeftiArr
    // return undefined


}